import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  handleSetSegmentName,
  handleSetSiteName,
} from "../../../../../lib/createObs/createObsFuncs";
import { useCreateObservationStore } from "../../../../../lib/createObs/useCreateObservationData";
import useCreateValidation from "../../../../../lib/createObs/useCreateValidation";
import { useFetchSiteSgmntListByProject } from "../../../../../queries/useFetchOptions";
import { useSelector } from "react-redux";

const SiteSegmentInputs = ({
  errors,
  register,
  setChangeTracker,
  handleSetSegmentList,
  customSegmentList,
}) => {
  const { t } = useTranslation();
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const {
    siteSegmentList,

    siteSegmentLoading,
    refetchSiteSegment,
    siteSegmentIsRefetching,
  } = useFetchSiteSgmntListByProject(localProjectId);
  const {
    localSiteValidation,
    setLocalNestRelocateSiteValidation,
    setLocalSiteValidation,
  } = useCreateValidation();
  const { setSiteState, setSegmentState, setDisplaySegment, setDisplaySite } =
    useCreateObservationStore();

  useEffect(() => {
    if (localProjectId) {
      refetchSiteSegment();
    }
  }, [localProjectId, refetchSiteSegment]);
  return (
    <div className="row px-3 modal__obs__details__row mt-1">
      <div className="col-5 modal__detail__title">
        {t("site")}, {t("segment")}
      </div>
      <div className="col-7 modal__title__value d-flex">
        <div className="col-6  me-1" style={{ width: "50%" }}>
          <select
            className={`crud_select crud-input ${
              errors.site ? "is-invalid" : ""
            }`}
            name="site"
            id="site"
            {...register("site")}
            onChange={(e) => {
              setSiteState(e.target.value);
              setLocalSiteValidation(false);
              setLocalNestRelocateSiteValidation(false);
              handleSetSiteName(
                e.target.value,
                siteSegmentList,
                setDisplaySite
              );
              setChangeTracker(true);
              handleSetSegmentList(e.target.value);
              // setcustomSegmentList(s.segment);
            }}
            style={{
              cursor: `${
                siteSegmentLoading || siteSegmentIsRefetching
                  ? "not-allowed"
                  : "pointer"
              }`,
              width: "100%",
            }}
            disabled={
              siteSegmentLoading || siteSegmentIsRefetching ? true : false
            }
          >
            {siteSegmentLoading || siteSegmentLoading ? (
              <option disabled selected value="">
                Loading...
              </option>
            ) : (
              <option disabled selected value="">
                ({` ${t("select")}`})
              </option>
            )}

            {siteSegmentList &&
              siteSegmentList !== "undefined" &&
              siteSegmentList.data.map((s, i) => (
                <option value={s.siteId} key={i}>
                  {s.site}
                </option>
              ))}
          </select>
          <p className="error">
            {errors.site?.message
              ? errors.site?.message
              : localSiteValidation
              ? `${t("required")}`
              : ""}
          </p>
        </div>
        <div className="col-6 w-100" style={{ width: "52%" }}>
          <select
            // {...register("segment")}
            className={`crud_select crud-input

         ${errors.site ? "is-invalid" : ""}

          `}
            {...register("segment")}
            id="segment"
            name="segment"
            style={{
              cursor: `${
                siteSegmentLoading || siteSegmentIsRefetching
                  ? "not-allowed"
                  : "pointer"
              }`,
              width: "50%",
            }}
            disabled={
              siteSegmentLoading || siteSegmentIsRefetching ? true : false
            }
            onChange={(e) => {
              setSegmentState(e.target.value);
              setDisplaySegment(e.target.innerText);
              handleSetSegmentName(
                e.target.value,
                customSegmentList,
                setDisplaySegment
              );
              setChangeTracker(true);
            }}
          >
            <option disabled selected value="">
              ({`${t("select")}`})
            </option>

            {customSegmentList &&
              customSegmentList.map((s, i) => (
                <option value={s.id} key={i}>
                  {s.name}
                </option>
              ))}
          </select>
          <p className="error">{errors.segment?.message}</p>
        </div>
      </div>
    </div>
  );
};

export default SiteSegmentInputs;
