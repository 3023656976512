import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useFetchFamilyList,
  useFetchGroupList,
  useFetchSeSpeciesList,
} from "../../../../../queries/useFetchOptions";
import { useEditObservationStore } from "../../../../../lib/editObservation/useEditObservationData";

const EditGroupFamSpecie = ({
  editData,
  // observation,
  groupChangeTracker,
  setGroupChangeTracker,
}) => {
  const {
    classData,
    family,
    species,
    observation,
    displaySpecies,
    displayGroupe,
    displayFamily,

    setDisplayGroupe,
    setDisplaySpecies,
    setDisplayFamily,
    setClassData,
    setFamily,
    setSpecies,
    setChangeTracker,
  } = useEditObservationStore();

  const { t } = useTranslation();
  useEffect(() => {
    if (editData) {
      setDisplayGroupe(editData.group);
      setDisplaySpecies(editData.specie);
      setDisplayFamily(editData.family);

      setClassData(editData.groupId);
      setFamily(editData.familyId);
      setSpecies(editData.specieId);
    }
  }, [editData]);

  //****** */ End of data fetching and setting***********

  const {
    speciesList,
    speciesLoading,
    speciesIsRefetching,
    refetchSpeciesList,
  } = useFetchSeSpeciesList(family);

  const { familyList, familyLoading, familyIsRefetching, familyListRefetch } =
    useFetchFamilyList(classData);
  const { groupeList } = useFetchGroupList();

  const [upDatedGroupeList, setUpDatedGroupeList] = useState([]);
  const [upDatedFamilyList, setUpDatedFamilyList] = useState([]);
  const [upDatedSpeciesList, setUpDatedSpeciesList] = useState([]);

  const handleSetGroupeName = (target) => {
    if (groupeList) {
      groupeList.data.map((s) => {
        if (Number(target) === s.id) {
          setDisplayGroupe(s.name);
        }
        return s.name;
      });
    }
  };

  const handleSetFamilyName = (target) => {
    if (familyList) {
      familyList.data.map((s) => {
        if (Number(target) === s.id) {
          setDisplayFamily(s.name);
        }
        return s.name;
      });
    }
  };

  const handleSetSpeciesName = (target) => {
    if (speciesList) {
      speciesList.data.map((s) => {
        if (Number(target) === s.id) {
          setDisplaySpecies(s.name);
        }
        return s.name;
      });
    }
  };
  useEffect(() => {
    if (classData) {
      familyListRefetch();
    }
    if (familyList && familyList.data && familyList.data.length !== 0) {
      setFamily(familyList.data[0].id);
      setDisplayFamily(familyList.data[0].name);
    }
  }, [
    classData,
    familyList,
    observation,
    familyListRefetch,
    groupChangeTracker,
  ]);

  useEffect(() => {
    if (family) {
      refetchSpeciesList();
    }
    if (speciesList && speciesList.data && speciesList.data.length !== 0) {
      setSpecies(speciesList.data[0].id);
      setDisplaySpecies(speciesList.data[0].name);
    }
  }, [
    classData,
    family,
    observation,
    displayFamily,
    speciesList,
    groupChangeTracker,
    refetchSpeciesList,
  ]);

  useEffect(() => {
    refetchSpeciesList();
  }, [family, groupeList, familyList, refetchSpeciesList]);
  useEffect(() => {
    if (!groupeList || !familyList || !speciesList) return;

    if ([1, 2, 3].includes(Number(observation))) {
      const filteredGroupe = groupeList.data.filter(
        (item) => Number(item.id) === 2
      );

      if (Number(observation) !== Number(editData.TypeObservationId)) {
        setClassData(filteredGroupe[0].id);
        setDisplayGroupe(filteredGroupe[0].name);
      }
      setUpDatedGroupeList(filteredGroupe.length ? filteredGroupe : []);
    } else {
      setUpDatedGroupeList(groupeList.data);
    }

    if (Number(classData) === 2 && [1, 2, 3].includes(Number(observation))) {
      const newArray = familyList.data.filter((item) =>
        ["4"].includes(item.id.toString())
      );

      if (Number(observation) !== Number(editData.TypeObservationId)) {
        setFamily(newArray[0]?.id);
        setDisplayFamily(newArray[0]?.name);
      }
      setUpDatedFamilyList(newArray);
    } else {
      setUpDatedFamilyList(familyList.data);
    }
    if (Number(observation) !== Number(editData.TypeObservationId)) {
      if (speciesList) {
        setSpecies(speciesList.data[0]?.id);
        setDisplaySpecies(speciesList.data[0]?.name);
      }
    }
    setUpDatedSpeciesList(speciesList.data);
  }, [
    groupeList,
    groupChangeTracker,
    editData,
    displayFamily,
    observation,
    classData,
    familyList,
    family,
    speciesList,
  ]);

  return (
    <div
      style={{
        borderBottom: ".4px solid #cccc ",
      }}
      className="row px-3 pb-3"
    >
      <div
        className="col-12 "
        style={{
          padding: "0px",
          paddingLeft: "10px",
          margin: "0px",
        }}
      >
        <label className="modal__detail__title mb-2">
          {t("group")} / {t("family")} / {t("species")}
        </label>
        <div className="species-select-container d-flex ">
          <div className="me-2 species-select-item">
            <select
              defaultValue={classData}
              onChange={(e) => {
                setClassData(e.target.value);
                setChangeTracker(true);
                handleSetGroupeName(e.target.value);
                setGroupChangeTracker(true);
              }}
              className="crud_select_class crud-input"
            >
              <option
                value={classData}
                style={{
                  color: "#073b60",
                  fontWeight: "bold",
                }}
                // disabled
              >
                {displayGroupe}
              </option>
              {upDatedGroupeList &&
                upDatedGroupeList
                  .filter((s) => s.id !== classData)
                  .map((s, i) => (
                    <option value={s.id} key={i}>
                      {s.name}
                    </option>
                  ))}
            </select>
          </div>
          <div className="me-2 species-select-item">
            <select
              defaultValue={family}
              onChange={(e) => {
                setFamily(e.target.value);
                handleSetFamilyName(e.target.value);
                setChangeTracker(true);
              }}
              className="crud_select_class crud-input"
              style={{
                cursor: `${
                  familyLoading || familyIsRefetching
                    ? "not-allowed"
                    : "pointer"
                }`,
              }}
              disabled={familyLoading || familyIsRefetching ? true : false}
            >
              {familyLoading || familyIsRefetching ? (
                <option disabled selected value="">
                  Loading...
                </option>
              ) : (
                <option
                  value={family}
                  style={{
                    color: "#073b60",
                    fontWeight: "bold",
                  }}
                  // disabled
                >
                  {displayFamily}
                </option>
              )}
              {upDatedFamilyList &&
                upDatedFamilyList
                  .filter((s) => s.id !== family)
                  .map((s, i) => (
                    <option value={s.id} key={i}>
                      {s.name}
                    </option>
                  ))}
            </select>
          </div>
          <div className="me-2 species-select-item">
            <select
              defaultValue={species}
              onChange={(e) => {
                setSpecies(e.target.value);
                handleSetSpeciesName(e.target.value);

                setChangeTracker(true);
              }}
              className="crud_select_class crud-input"
              style={{
                cursor: `${
                  speciesLoading || speciesIsRefetching
                    ? "not-allowed"
                    : "pointer"
                }`,
              }}
              disabled={
                familyLoading ||
                familyIsRefetching ||
                speciesLoading ||
                speciesIsRefetching
                  ? true
                  : false
              }
            >
              {familyLoading ||
              familyIsRefetching ||
              speciesLoading ||
              speciesIsRefetching ? (
                <option selected value="">
                  Loading...
                </option>
              ) : (
                <option
                  value={`${species}`}
                  style={{
                    color: "#073b60",
                    fontWeight: "bold",
                  }}
                  // disabled
                >
                  {displaySpecies}
                </option>
              )}

              {upDatedSpeciesList &&
                upDatedSpeciesList
                  .filter((s) => s.id !== species)
                  .map((s, i) => (
                    <option value={s.id} key={i}>
                      {s.name}
                    </option>
                  ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGroupFamSpecie;
