import React from "react";
import { observationStyle } from "../../utils/TypeObsStyle";
import { obsertionTypes } from "../../constants/observationsTypes";

const ObservationTypePill = ({ displayObserVationType }) => {
  return (
    <span
      className=" p-1 d-inline obs__type "
      // style={{ fontSize: ".6rem" }}
      style={
        displayObserVationType === obsertionTypes.threat.VE ||
        displayObserVationType === obsertionTypes.threat.VF
          ? observationStyle.Threat
          : displayObserVationType === obsertionTypes.crawl.VE ||
            displayObserVationType === obsertionTypes.crawl.VF
          ? observationStyle.Crawls
          : displayObserVationType === obsertionTypes.animal.VE
          ? observationStyle.Animal
          : displayObserVationType === obsertionTypes.flash.VE ||
            displayObserVationType === obsertionTypes.flash.VF
          ? observationStyle.FlashObs
          : displayObserVationType === obsertionTypes.presence.VE ||
            displayObserVationType === obsertionTypes.presence.VF
          ? observationStyle.SignsPresence
          : displayObserVationType === obsertionTypes.female.VE ||
            displayObserVationType === obsertionTypes.female.VF
          ? observationStyle.FemaleEn
          : displayObserVationType === obsertionTypes.nest.VE ||
            displayObserVationType === obsertionTypes.nest.VF
          ? observationStyle.Nest
          : observationStyle.noStyle
      }
    >
      {displayObserVationType}
    </span>
  );
};

export default ObservationTypePill;
