import React from "react";
import { useEditObservationStore } from "../../../../../../lib/editObservation/useEditObservationData";

const EditFemaleEncounter = ({ editData }) => {
  const {
    setNewFemEncTagLeft,
    setNewFemEncTagRight,
    setNewFemEncLength,
    setNewFemEncWidth,
    setChangeTracker,
  } = useEditObservationStore();
  return (
    <>
      <div
        className="row px-3 modal__obs__details__row mt-1"
        style={{ borderTop: ".5px solid #CCCCCC" }}
      >
        <div className="col-5 modal__detail__title">Female Tag ID Left</div>
        <div className="col-7 modal__title__value">
          <input
            type="text"
            id="fem enc tag left"
            name="femEncTagLeft"
            className={`crud_select crud-input`}
            style={{ width: "101%" }}
            // value={editData.femaleTagLeft} Needs to be added to API
            onChange={(e) => {
              setNewFemEncTagLeft(e.target.value);
              setChangeTracker(true);
            }}
          />
        </div>
      </div>

      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">Female Tag ID Right</div>
        <div className="col-7 modal__title__value">
          <input
            type="text"
            id="fem enc tag right"
            name="femEncTagRight"
            className={`crud_select crud-input`}
            style={{ width: "101%" }}
            // value={editData.femaleTagRight} Needs to be added to API
            onChange={(e) => {
              setNewFemEncTagRight(e.target.value);
              setChangeTracker(true);
            }}
          />
        </div>
      </div>

      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">Length</div>
        <div className="col-7 modal__title__value">
          <div className="input-container">
            <input
              type="numeric"
              id="fem-enc-length"
              name="femEncLength"
              className={`crud_select crud-input`}
              style={{ width: "101%" }}
              // value={editData.length} Needs to be added to API
              onChange={(e) => {
                setNewFemEncLength(e.target.value);
                setChangeTracker(true);
              }}
            />
            <span className="unit">(cm)</span>
          </div>
        </div>
      </div>

      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">Width</div>
        <div className="col-7 modal__title__value">
          <div className="input-container">
            <input
              type="numeric"
              id="fem-enc-width"
              name="femEncWidth"
              className={`crud_select crud-input`}
              style={{ width: "101%" }}
              // value={editData.width} Needs to be added to API
              onChange={(e) => {
                setNewFemEncWidth(e.target.value);
                setChangeTracker(true);
              }}
            />
            <span className="unit">(cm)</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFemaleEncounter;
