import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateObservationStore } from "../../../../../lib/createObs/useCreateObservationData";
import useCreateValidation from "../../../../../lib/createObs/useCreateValidation";
import {
  handleSetFamilyName,
  handleSetGroupeName,
  handleSetSpeciesName,
} from "../../../../../lib/createObs/createObsFuncs";
import {
  useFetchFamilyList,
  useFetchGroupList,
  useFetchSeSpeciesList,
} from "../../../../../queries/useFetchOptions";

const GroupFamSpecie = ({ register, errors, setChangeTracker }) => {
  const { groupeList } = useFetchGroupList();
  const {
    obsTypeState,
    groupeState,
    familyState,

    setGroupeState,
    setFamilyState,
    setSpeciesListState,
    setObsTypeState,
    setDisplaySpecies,
    setDisplayGroupe,
    setDisplayFamily,
  } = useCreateObservationStore();

  const { familyList, familyListRefetch, familyLoading, familyIsRefetching } =
    useFetchFamilyList(groupeState);
  const {
    speciesList,
    speciesLoading,
    speciesIsRefetching,
    refetchSpeciesList,
  } = useFetchSeSpeciesList(familyState);

  useEffect(() => {
    if (groupeState) {
      familyListRefetch();
    }
    if (familyList && familyList.data.length !== 0) {
      setFamilyState(familyList.data[0].id);
    }
  }, [groupeState, familyList, familyListRefetch]);
  useEffect(() => {
    if (familyState) {
      refetchSpeciesList();
    }
  }, [familyState, groupeState, refetchSpeciesList]);

  const {
    localSpeciesState,
    localGroupValidation,
    localFamilyValidation,
    setLocalSpeciesState,
    setLocalGroupValidation,

    setLocalFamilyValidation,
  } = useCreateValidation();

  const { t } = useTranslation();
  const [upDatedGroupeList, setUpDatedGroupeList] = useState([]);
  const [upDatedFamilyList, setUpDatedFamilyList] = useState([]);
  useEffect(() => {
    if (obsTypeState === "1" || obsTypeState === "2" || obsTypeState === "3") {
      if (groupeList) {
        groupeList.data.map((item) => {
          if (item.id === 2) {
            setUpDatedGroupeList([item]);
          }
          setGroupeState("2");
          setLocalGroupValidation(false);
          handleSetGroupeName("2", groupeList, setDisplayGroupe);
        });
      }
    } else {
      if (groupeList) {
        setUpDatedGroupeList(groupeList.data);
        // setLocalGroupValidation(true);
      }
    }
    if (
      groupeState === "2" &&
      (obsTypeState === "1" || obsTypeState === "2" || obsTypeState === "3")
    ) {
      if (familyList) {
        const newArray = familyList.data.filter((item) =>
          ["4"].includes(item.id.toString())
        );
        setUpDatedFamilyList(newArray);
      }
    } else if (familyList) {
      setUpDatedFamilyList(familyList.data);
    }
    if (groupeState !== "2") {
      if (familyList) {
        setUpDatedFamilyList(familyList.data);
      }
    }
  }, [groupeList, obsTypeState, groupeState, familyList]);

  return (
    <div
      style={{
        borderBottom: ".5px solid #CCCCCC",
      }}
      className="row px-3   "
    >
      <div
        className="col-12 "
        style={{
          padding: "0px",
          paddingLeft: "12px",
          margin: "0px",
        }}
      >
        <label className="modal__detail__title my-2">
          {t("group")} / {t("family")} / {t("species")} <sup>*</sup>
        </label>
        <div className=" species-select-container d-flex ">
          <div className="me-2 species-select-item">
            <select
              // {...register("groupe")}
              placeholder="groupe"
              name="groupe"
              id="groupe"
              onChange={(e) => {
                setGroupeState(e.target.value);
                setLocalGroupValidation(false);
                handleSetGroupeName(
                  e.target.value,
                  groupeList,
                  setDisplayGroupe
                );
                // setDisplayGroupe(e.target.innerText);
                setChangeTracker(true);
              }}
              className={`crud_select crud-input ${
                localGroupValidation ? "is-invalid" : ""
              }`}
            >
              {(!obsTypeState ||
                (obsTypeState !== "1" &&
                  obsTypeState !== "2" &&
                  obsTypeState !== "3")) && (
                <option disabled selected value="">
                  {t("group")}
                </option>
              )}

              {/* groupeList */}

              {upDatedGroupeList &&
                upDatedGroupeList.map((s, i) => (
                  <option value={s.id} key={i}>
                    {s.name}
                  </option>
                ))}
            </select>

            {localGroupValidation && <p className="error">{t("required")}</p>}
            {/* <p className="error">
              {errors.groupe?.message
                ? errors.groupe?.message
                : localGroupValidation
                ? `${t("required")}`
                : ""}
            </p> */}
          </div>
          <div className="me-2 species-select-item">
            <select
              // {...register("family")}
              onChange={(e) => {
                setFamilyState(e.target.value);
                setLocalFamilyValidation(false);
                handleSetFamilyName(
                  e.target.value,
                  familyList,
                  setDisplayFamily
                );

                // setDisplayFamily(e.target.innerText);
                setChangeTracker(true);
              }}
              id="family"
              name="family"
              placeholder="family"
              className={`crud_select crud-input ${
                localFamilyValidation ? "is-invalid" : ""
              }`}
              style={{
                cursor: `${
                  familyLoading || familyIsRefetching
                    ? "not-allowed"
                    : "pointer"
                }`,
              }}
              disabled={familyLoading || familyIsRefetching ? true : false}
            >
              {obsTypeState !== "1" &&
              obsTypeState !== "2" &&
              obsTypeState !== "3" ? (
                familyLoading || familyIsRefetching ? (
                  <option disabled selected value="">
                    Loading...
                  </option>
                ) : (
                  <option disabled selected value="">
                    {t("family")}
                  </option>
                )
              ) : null}

              {upDatedFamilyList &&
                upDatedFamilyList.map((s, i) => (
                  <option value={s.id} key={i}>
                    {s.name}
                  </option>
                ))}
            </select>

            {localFamilyValidation && <p className="error">{t("required")}</p>}
            {/* <p className="error">
              {errors.family?.message
                ? errors.family?.message
                : localFamilyValidation
                ? `${t("required")}`
                : ""}
            </p> */}
          </div>
          <div className="me-2 species-select-item">
            <select
              // {...register("specie")}
              placeholder="specie"
              onChange={(e) => {
                setSpeciesListState(e.target.value);
                setLocalSpeciesState(false);
                handleSetSpeciesName(
                  e.target.value,
                  speciesList,
                  setDisplaySpecies
                );
                // setDisplaySpecies(e.target.innerText);
                setChangeTracker(true);
              }}
              id="specie"
              name="specie"
              className={`crud_select crud-input ${
                localSpeciesState ? "is-invalid" : ""
              }`}
              style={{
                cursor: `${
                  speciesLoading || speciesIsRefetching
                    ? "not-allowed"
                    : "pointer"
                }`,
              }}
              disabled={speciesLoading || speciesIsRefetching ? true : false}
            >
              {speciesLoading || speciesIsRefetching ? (
                <option disabled selected value="">
                  Loading...
                </option>
              ) : (
                <option disabled selected value="">
                  {t("species")}
                </option>
              )}

              {speciesList &&
                speciesList.data.map((s, i) => (
                  <option value={s.id} key={i}>
                    {s.name}
                  </option>
                ))}
            </select>

            {localSpeciesState && <p className="error">{t("required")}</p>}
            {/* <p className="error">
              {errors.specie?.message
                ? errors.specie?.message
                : localSpeciesState
                ? `${t("required")}`
                : ""}
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupFamSpecie;
