import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";

import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  useFetchObservaionTypeList,
  useFetchSiteSgmntListByProject,
} from "../../../../queries/useFetchOptions";
import {
  createObservaion,
  createObservationLocally,
} from "../../../../actions/projectActions";

// form Validation imports
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatDate5 } from "../../../../utils/DateFormater";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import unavailableMapPlaceHolder from "../../../../assets/images/unavailableMapPlaceholder.svg";
import uploadImagePlaceHolder from "../../../../assets/images/uploadImagePlaceholder.svg";
import {
  useFetchMiniCardObservaionList,
  useFetchObservaionList,
  useFetchUnvalidatedObservaionList,
} from "../../../../queries/useMapQueriesHook";
import { useTranslation } from "react-i18next";

import ObservationTypePill from "../../ObservationTypePill";
import { obsertionTypes } from "../../../../constants/observationsTypes";
import { useCreateObservationStore } from "../../../../lib/createObs/useCreateObservationData";
import useCreateValidation from "../../../../lib/createObs/useCreateValidation";
import ConfirmExit from "./createMolecules/ConfirmExit";
import ImagesUpload from "./createMolecules/ImagesUpload";
import { handleSetObservationType } from "../../../../lib/createObs/createObsFuncs";

import CoordinatesInputs from "./createMolecules/CoordinatesInputs";
import GroupFamSpecie from "./createMolecules/GroupFamSpecie";
import SiteSegmentInputs from "./createMolecules/SiteSegmentInputs";
import CreateNewNest from "./createMolecules/obsDynamicFields/CreateNewNest";
import CreateNewFemaleEncounter from "./createMolecules/obsDynamicFields/CreateNewFemaleEncounter";
import ModalsMap from "../../modalsMolecules/ModalsMap";
import DateTimeSelector from "../../modalsMolecules/DateTimeSelector";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

dayjs.extend(customParseFormat);

const CreateObservation = ({ handleHideModalCreateModal }) => {
  // validation
  const {
    localObsTypeValidation,
    setLocalLatValidation,
    setLocalObsTypeValidation,
    setLocalNestHealthValidation,
    setLocalSpeciesState,
    setLocalGroupValidation,
    setLocalSiteValidation,
    setLocalFamilyValidation,
    setLocalNestIDValidation,
    setLocalNestRelocationValidation,
    setLocalNestRelocateSiteValidation,
    setLocalSuspectedNestCreationDateValidation,
    setLocalNestCollectorValidation,
    setLocalNestHighTideDistValidation,
    setLocalNestStatusValidation,
    setLocalNestEstHatchDateValidation,
    setLocalNestEstExhumDateValidation,
    setLocalFemEncTagLeftValidation,
    setLocalFemEncTagRightValidation,
    setLocalFemEncLengthValidation,
    setLocalFemEncWidthValidation,
    setLocalCrawlWidthValidation,
    setLocalNotesValidation,
    resetAllValidations,
  } = useCreateValidation();

  // new Form data management
  const {
    newDate,
    currentTime,
    seconds,
    newLocalLat,
    newLocalLong,
    obsTypeState,
    groupeState,
    familyState,
    speciesListState,
    siteState,
    relocateSite,
    segmentState,
    deadOrAlive,
    status,
    images,
    imageData,
    nestID,
    nestCollector,
    nestHighTideDist,
    nestStatus,
    nestHealth,
    nestRelocation,
    suspectedNestCreationDate,
    nestEstHatchDate,
    nestEstExhumDate,
    femEncTagLeft,
    femEncTagRight,
    femEncLength,
    femEncWidth,
    crawlWidth,
    notes,
    isFemalePresent,
    displaySpecies,
    displayGroupe,
    displayFamily,
    displaySegment,
    displaySite,

    setNewDate,
    setSeconds,
    setNewLocalLat,
    setNewLocalLong,
    setObsTypeState,
    setDeadOrAlive,
    setStatus,
    resetCreateValues,
    setNotes,
  } = useCreateObservationStore();

  const { t } = useTranslation();

  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const { queryRefetchObsList } = useFetchUnvalidatedObservaionList({
    id: localProjectId,
  });
  const { queryRefetchValidObsList } = useFetchObservaionList({
    id: localProjectId,
  });
  const { miniCardQueryRefetchObsList } = useFetchMiniCardObservaionList({
    id: localProjectId,
  });
  const successMessage = useSelector((state) => state.setSuccessMessage);
  const { createObsSuccessNotif } = successMessage;

  useEffect(() => {
    var x = document.getElementById("demo");
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        x.innerHTML = "Geolocation is not supported by this browser.";
      }
    }
    getLocation();
  }, []);

  // validation

  const validationSchema = yup.object().shape({
    coords: yup.string().required(`${t("required")}`),
    site: yup.string(),
    segment: yup.string(),
    observationType: yup.string().required(`${t("required")}`),
    groupe: yup.string().required(`${t("required")}`),
    family: yup.string().required(`${t("required")}`),
    specie: yup.string().required(`${t("required")}`),
  });

  const {
    register,

    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  function getRandomNumber() {
    let seconds = Math.floor(Math.random() * 60)
      .toString()
      .padStart(2, "0");
    setSeconds(seconds);
  }
  useEffect(() => {
    getRandomNumber();
  }, []);

  const { siteSegmentList, refetchSiteSegment } =
    useFetchSiteSgmntListByProject(localProjectId);
  const [customSegmentList, setCustomSegmentList] = useState(null);

  const handleSetSegmentList = (seletedSiteId) => {
    if (siteSegmentList) {
      siteSegmentList.data.map((site) => {
        if (site.siteId === seletedSiteId) {
          setCustomSegmentList(site.segment);
        }
        return site.segment;
      });
    }
  };

  useEffect(() => {
    if (localProjectId) {
      refetchSiteSegment();
    }
  }, [localProjectId, refetchSiteSegment]);

  const { observationList } = useFetchObservaionTypeList();

  const [user, setuser] = useState();
  useEffect(() => {
    if (userInfo) {
      setuser(userInfo.data.email);
    }
  }, []);

  const dispatch = useDispatch();

  // Upload images function

  const [showUploadUI, setShowUploadUI] = useState(false);

  let currentProject = JSON.parse(localStorage.getItem("selectedProject"));
  // setSelectedProject(currentProject);

  const onSubmit = (data) => {
    // setConfirmExit(false);
    const createObject = {
      projectId: currentProject && currentProject.id,
      segment: segmentState,
      site: siteState,
      obsType: obsTypeState,
      groupe: groupeState,
      family: familyState,
      species: speciesListState,
      time: currentTime + `:${seconds}`,
      coordX: newLocalLat,
      coordY: newLocalLong,
      date: convertedDate,
      imgs: imageData,
      dead: deadOrAlive,
    };

    // Local project update
    const timeStr = currentTime + `:${seconds}`;
    const dateStr = convertedDate;

    // Splitting time into hours, minutes, and seconds
    const [hours, minutes, secondss] = timeStr.split(":");

    // Splitting date into year, month, and day
    const [year, month, day] = dateStr.split("-");

    // Setting the desired timezone
    // Adjusting the UTC offset
    const dt = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, secondss)
    );

    // Creating the final object
    const localdateObject = {
      date: dt.toISOString().slice(0, 23),
      timezone_type: 3,
      timezone: "UTC",
    };

    const updtedObservationData = {
      id: "",
      projectId: currentProject && currentProject.id,
      projectName: currentProject && currentProject.name,
      idInaturalist: null,
      coordX: newLocalLat,
      coordY: newLocalLong,
      note: "",
      alpha: "",
      collector: "",
      images: {
        img1: imageData.img1,
        img2: imageData.img2,
        img3: imageData.img3,
        img4: imageData.img4,
      },
      dead: deadOrAlive,
      status: 0,
      TypeObservationId: obsTypeState,
      TypeObservation: displayObserVationType,
      specieId: speciesListState,
      specie: displaySpecies,
      familyId: familyState,
      family: displayFamily,
      groupId: groupeState,
      group: displayGroupe,
      user: user,
      date: localdateObject,
      updatedate: null,
      segment: displaySegment,
      site: displaySite,
    };

    dispatch(createObservaion(createObject));
    dispatch(createObservationLocally(updtedObservationData));
    handleHideModalCreateModal();
  };
  useEffect(() => {
    if (createObsSuccessNotif && createObsSuccessNotif === true) {
      queryRefetchObsList();
      resetCreateValues();
      resetAllValidations();
      queryRefetchValidObsList();
      miniCardQueryRefetchObsList();
    }
  }, [createObsSuccessNotif]);

  /**
   * Handles the creation of a new observation.
   *
   * This function performs the following actions:
   * - Checks if the required fields (latitude, longitude, observation type, species, and site) are filled out. If not, it sets the corresponding validation flags.
   * - If all required fields are filled out, it calls the `onSubmit()` function to create the observation.
   * - For observation types "Nest VE" and "Nest VF", it checks if additional nest-related fields are filled out and sets the corresponding validation flags.
   * - For observation types "Female VE" and "Female VF", it checks if the female encounter length and width are provided and sets the corresponding validation flags.
   * - If any validation fails, it sets the `confirmExit` flag to `false`.
   */
  const handleCreateObs = () => {
    if (
      newLocalLat &&
      newLocalLong &&
      obsTypeState &&
      speciesListState &&
      siteState
    ) {
      onSubmit();
    }
    if (!newLocalLat) {
      setLocalLatValidation(true);
    }
    if (!obsTypeState) {
      setLocalObsTypeValidation(true);
    }
    if (!speciesListState) {
      setLocalSpeciesState(true);
    } else {
      setLocalSpeciesState(false);
    }
    if (!groupeState) {
      setLocalGroupValidation(true);
    } else {
      setLocalGroupValidation(false);
    }
    if (!familyState) {
      setLocalFamilyValidation(true);
    } else {
      setLocalFamilyValidation(false);
    }
    if (!siteState) {
      setLocalSiteValidation(true);
    }

    if (
      displayObserVationType === obsertionTypes.nest.VE ||
      displayObserVationType === obsertionTypes.nest.VF
    ) {
      if (!nestID) {
        setLocalNestIDValidation(true);
      }

      if (!nestStatus) {
        setLocalNestStatusValidation(true);
      }
      if (!nestHealth) {
        setLocalNestHealthValidation(true);
      }
      if (!nestRelocation) {
        setLocalNestRelocationValidation(true);
      }
      if (nestRelocation) {
        if (!relocateSite) {
          setLocalNestRelocateSiteValidation(true);
        } else {
          setLocalNestRelocateSiteValidation(false);
        }
      }
      if (!isFemalePresent && !suspectedNestCreationDate) {
        setLocalSuspectedNestCreationDateValidation(true);
      }
      // if (!nestCollector) {
      //   setLocalNestCollectorValidation(true);
      // }
      // if (nestHighTideDist === 0) {
      //   setLocalNestHighTideDistValidation(true);
      // }

      if (!nestEstHatchDate) {
        setLocalNestEstHatchDateValidation(true);
      }
      if (!nestEstExhumDate) {
        setLocalNestEstExhumDateValidation(true);
      }
    }

    if (
      displayObserVationType === obsertionTypes.female.VE ||
      displayObserVationType === obsertionTypes.female.VF
    ) {
      // if (!femEncTagLeft) {
      //   setLocalFemEncTagLeftValidation(true);
      // }
      // if (!femEncTagRight) {
      //   setLocalFemEncTagRightValidation(true);
      // }
      if (femEncLength === 0) {
        setLocalFemEncLengthValidation(true);
      }
      if (femEncWidth === 0) {
        setLocalFemEncWidthValidation(true);
      }
    }

    // if (crawlWidth === 0) {
    //   setLocalCrawlWidthValidation(true);
    // }
    if (!notes) {
      setLocalNotesValidation(true);
    }

    setConfirmExit(false);
  };

  // exit box
  const [confirmExit, setConfirmExit] = useState(false);
  /**
   * Handles exiting the edit mode for the observation creation modal.
   * This function resets the create values, resets all validations, and hides the modal.
   */
  const handleExitEdit = () => {
    setConfirmExit(false);
    resetCreateValues();
    resetAllValidations();
    handleHideModalCreateModal();
  };
  const handleConfirmExit = () => {
    setConfirmExit(false);
  };

  // input tracker
  /**
   * Handles the logic for exiting the edit mode of the observation creation modal.
   * If there are unsaved changes (`changeTracker` is true), it will display a confirmation modal.
   * Otherwise, it will reset the create values, reset all validations, and hide the modal.
   */
  const [changeTracker, setChangeTracker] = useState(false);
  const handleShowConfirmModal = () => {
    if (changeTracker) {
      setConfirmExit(true);
    } else {
      handleExitEdit();
    }
  };

  // display observation type
  const [displayObserVationType, setDisplayObserVationType] = useState(null);

  /**
   * Callback function that updates the state with the user's current latitude and longitude coordinates obtained from the browser's geolocation API.
   *
   * @param {Object} position - An object containing the user's latitude and longitude coordinates.
   * @param {number} position.coords.latitude - The user's current latitude.
   * @param {number} position.coords.longitude - The user's current longitude.
   */
  function showPosition(position) {
    setNewLocalLat(position.coords.latitude);
    setNewLocalLong(position.coords.longitude);
  }

  /**
   * Retrieves the user's current location using the browser's geolocation API.
   * If the browser supports geolocation, it calls the `showPosition` function with the latitude and longitude coordinates.
   * If the browser does not support geolocation, it returns a message indicating that geolocation is not supported.
   */
  useEffect(() => {
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        return "Geolocation is not supported by this browser.";
      }
    }
    getLocation();
  }, []);

  const cordRef = useRef();

  const [newLat, setNewLat] = useState(null);
  const [newLong, setNewLong] = useState(null);

  let convertedDate = formatDate5(newDate);

  return (
    <div className="mvp__modals">
      {/* {user && } */}

      <Modal
        show={true}
        onHide={handleHideModalCreateModal}
        backdrop="static"
        centered
        className="mx-auto mvp__modals create__modal"
        keyboard={false}
        size="lg"
        dialogClassName="modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="bgPrimary modal__header text-left  "
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title className="modal__header__title">
            {t("createObs")}
          </Modal.Title>
          <div className="">
            <span
              className="pointer"
              onClick={() => {
                // setSaveConfirmation(true);
                // setConfirmExit(true);
                handleShowConfirmModal();
              }}
            >
              <RxCross1 className="close-modal-icon" />
            </span>
          </div>
        </Modal.Header>

        {!showUploadUI ? (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className="modal-top"
                style={{ borderBottom: "0.3px solid #ebf0ec" }}
              >
                <div
                  className="modal-map"
                  style={{ width: "50%", height: "100%" }}
                >
                  {!newLocalLat && !newLocalLong ? (
                    <img
                      src={unavailableMapPlaceHolder}
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      alt=""
                    />
                  ) : (
                    newLocalLat &&
                    newLocalLong && (
                      <ModalsMap
                        displayObserVationType={displayObserVationType}
                        newLat={newLat}
                        newLong={newLong}
                        newLocalLat={newLocalLat}
                        newLocalLong={newLocalLong}
                      />
                    )
                  )}
                </div>
                <div
                  className="modal-imgs "
                  style={{ width: "50%", height: "100%", position: "relative" }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    {images.length === 0 ? (
                      <img
                        onClick={() => {
                          setShowUploadUI(!showUploadUI);
                        }}
                        src={uploadImagePlaceHolder}
                        style={{
                          height: "100%",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        alt=""
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                          backgroundImage: `url(${images[0].data_url})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: " center center",
                          backgroundSize: "cover",
                        }}
                      />
                    )}
                  </div>
                  {images && images.length !== 0 && (
                    <div
                      className="modal-photo-btn create"
                      onClick={() => {
                        setShowUploadUI(!showUploadUI);
                      }}
                    >
                      {images && images.length} + photos {">"}
                    </div>
                  )}
                </div>
              </div>

              <div className="container-fluid modalBody">
                {/* OBS Display */}
                <div className="row ">
                  <div className="col-12 px-4 pt-1 ">
                    <span
                      className="f-bold me-2 single__obs__id"
                      // style={{ fontSize: "1.1rem" }}
                    >
                      Obs {currentProject && currentProject.id}
                    </span>
                    {displayObserVationType && (
                      <ObservationTypePill
                        displayObserVationType={displayObserVationType}
                      />
                    )}
                  </div>
                  <div className="col-5 text-end"></div>
                </div>

                {/* Project Name */}
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {t("projectName")}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {currentProject && currentProject.name}
                  </div>
                </div>

                {/* Author */}
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {"Observer name"}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {currentProject && currentProject.user}
                  </div>
                </div>

                {/* Date, Time */}

                <DateTimeSelector
                  errors={errors}
                  setChangeTracker={setChangeTracker}
                />

                {/* Coords */}
                <CoordinatesInputs
                  cordRef={cordRef}
                  setChangeTracker={setChangeTracker}
                  errors={errors}
                  newLong={newLong}
                  setNewLong={setNewLong}
                  newLat={newLat}
                  setNewLat={setNewLat}
                />

                {/* OBS Type */}
                <div className="row px-3 modal__obs__details__row mt-1">
                  <div className="col-5 modal__detail__title">
                    {t("obsType")} <sup>*</sup>
                  </div>
                  <div className="col-7 modal__title__value">
                    <select
                      {...register("observationType")}
                      placeholder="observation type"
                      id="observationType"
                      name="observationType"
                      className={`crud_select crud-input ${
                        errors.observationType ? "is-invalid" : ""
                      }`}
                      style={{ width: "101%" }}
                      onChange={(e) => {
                        setObsTypeState(e.target.value);
                        handleSetObservationType(
                          e.target.value,
                          observationList,
                          setDisplayObserVationType
                        );
                        setLocalObsTypeValidation(false);
                        setChangeTracker(true);
                      }}
                    >
                      <option>({`${t("select")}`})</option>
                      {observationList &&
                        observationList.map((s, i) => (
                          <option value={s.id} key={i}>
                            {s.name}
                          </option>
                        ))}
                    </select>

                    <p className="error">
                      {errors.observationType?.message
                        ? errors.observationType?.message
                        : localObsTypeValidation
                        ? `${t("required")}`
                        : ""}
                    </p>
                    {/* {localObsTypeValidation && (
                        <p className="error">This Field  is required</p>
                      )} */}
                  </div>
                </div>

                {/* Site, Segment */}

                <SiteSegmentInputs
                  errors={errors}
                  register={register}
                  setChangeTracker={setChangeTracker}
                  customSegmentList={customSegmentList}
                  handleSetSegmentList={handleSetSegmentList}
                />

                {/* Group / Family / Species */}
                <GroupFamSpecie
                  errors={errors}
                  register={register}
                  setChangeTracker={setChangeTracker}
                />

                {/* Life Status Slider
                <div
                  className="dead-alive  d-flex  px-3 py-2"
                  style={{
                    justifyContent: "space-between",
                    fontSize: "14px",
                    color: "#595959",
                    marginBottom: "5px",
                    background: "#fff",
                  }}
                >
                  <div className="col-5 modal__detail__title">{t("status")}</div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginRight: "15px",
                      }}
                    >
                      {status}
                    </div>
                    <Switch onChange={statusChange} />
                  </div>
                </div> */}

                {/* NEW NEST DYNAMIC BREAKPOINT*/}
                <div>
                  <div>
                    {(displayObserVationType === obsertionTypes.nest.VE ||
                      displayObserVationType === obsertionTypes.nest.VF) && (
                      <CreateNewNest
                        errors={errors}
                        setChangeTracker={setChangeTracker}
                        siteSegmentList={siteSegmentList}
                        customSegmentList={customSegmentList}
                      />
                    )}

                    {(displayObserVationType === obsertionTypes.female.VE ||
                      displayObserVationType === obsertionTypes.female.VF) && (
                      <CreateNewFemaleEncounter
                        errors={errors}
                        setChangeTracker={setChangeTracker}
                      />
                    )}

                    {/* Notes */}
                    <div className="row px-3 modal__obs__details__row mt-1">
                      <div className="col-5 modal__detail__title">
                        {"Notes"}
                      </div>
                      <div className="col-7 modal__title__value">
                        <input
                          type="text"
                          id="notes"
                          name="notes"
                          className={`crud_select crud-input ${
                            errors.notes ? "is-invalid" : ""
                          }`}
                          style={{ width: "101%" }}
                          onChange={(e) => {
                            setNotes(e.target.value);
                            setLocalNotesValidation(false);
                            setChangeTracker(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal-footers"
                style={{
                  borderRadius: "8px",
                }}
              >
                <div
                  className="d-flex justify-content-end"
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    className="modal_obs__btn"
                    onClick={() => {
                      handleShowConfirmModal();
                    }}
                  >
                    {t("cancelBtn")}
                  </div>
                  <div
                    // type="submit"
                    className="modal_obs__btn validate"
                    onClick={() => handleCreateObs()}
                  >
                    {t("createBtn")}
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : (
          <ImagesUpload
            setChangeTracker={setChangeTracker}
            setShowUploadUI={setShowUploadUI}
            showUploadUI={showUploadUI}
          />
        )}
      </Modal>

      {/* {confirmExit && ( */}
      <ConfirmExit
        confirmExit={confirmExit}
        handleCreateObs={handleCreateObs}
        handleExitEdit={handleExitEdit}
        handleConfirmExit={handleConfirmExit}
      />
    </div>
  );
};

export default CreateObservation;
