import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFetchObservaionTypeList } from "../../../../../queries/useFetchOptions";
import { useEditObservationStore } from "../../../../../lib/editObservation/useEditObservationData";
import { useTranslateObsType } from "../../../../../utils/useTranslateObservationType";

const EditObsType = ({ setGroupChangeTracker, editData }) => {
  const { t } = useTranslation();

  const {
    setDisplayObservationType,
    observation,
    setObservation,
    displayObserVationType,
    setChangeTracker,
  } = useEditObservationStore();
  const { observationList } = useFetchObservaionTypeList();

  const currentObsType = useTranslateObsType(editData.TypeObservation);

  useEffect(() => {
    if (editData) {
      setDisplayObservationType(currentObsType);
      setObservation(editData.TypeObservationId);
    }
  }, [editData, currentObsType]);
  const handleSetObservationType = (e) => {
    if (observationList) {
      for (let x of observationList) {
        if (Number(x.id) === Number(e)) {
          setDisplayObservationType(x.name);
        }
      }
    }
  };
  return (
    <div className="row px-3 modal__obs__details__row mt-1">
      <div className="col-5 modal__detail__title">
        {t("obsType")} <sup>*</sup>
      </div>
      <div className="col-7 modal__title__value">
        <select
          defaultValue={observation}
          onChange={(e) => {
            setObservation(e.target.value);
            handleSetObservationType(e.target.value);

            if (
              Number(e.target.value) === 1 ||
              Number(e.target.value) === 2 ||
              Number(e.target.value) === 3
            ) {
              setGroupChangeTracker(true);
            } else {
              setGroupChangeTracker(false);
            }
            setChangeTracker(true);
          }}
          style={{ width: "101%" }}
          className="crud_select crud-input"
        >
          <option
            value={`${observation}`}
            style={{
              color: "#073b60",
              fontWeight: "bold",
            }}
            // disabled
          >
            {displayObserVationType}
          </option>
          {observationList &&
            observationList
              .filter((obsType) => obsType.id !== observation)
              .map((s, i) => (
                <option value={s.id} key={i}>
                  {s.name}
                </option>
              ))}
        </select>
      </div>
    </div>
  );
};

export default EditObsType;
