export const obsertionTypes = {
  threat: {
    VE: "Threat",
    VF: "Menace",
  },
  crawl: {
    VE: "Crawl",
    VF: "Trace",
  },
  animal: {
    VE: "Animal",
    VF: "Animal",
  },
  flash: {
    VE: "Flash observation",
    VF: "Observation flash",
  },
  presence: {
    VE: "Sign of presence",
    VF: "Signe de présence",
  },
  female: {
    VE: "Female encounter",
    VF: "Rencontre tortue femelle",
  },
  nest: {
    VE: "Nest",
    VF: "Nid",
  },
};
