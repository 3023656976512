export const handleSetGroupeName = (target, groupeList, setDisplayGroupe) => {
  if (groupeList) {
    groupeList.data.map((s) => {
      if (Number(target) === s.id) {
        setDisplayGroupe(s.name);
      }
      return s.name;
    });
  }
};

export const handleSetFamilyName = (target, familyList, setDisplayFamily) => {
  if (familyList) {
    familyList.data.map((s) => {
      if (Number(target) === s.id) {
        setDisplayFamily(s.name);
      }
      return s.name;
    });
  }
};

export const handleSetSpeciesName = (
  target,
  speciesList,
  setDisplaySpecies
) => {
  if (speciesList) {
    speciesList.data.map((s) => {
      if (Number(target) === s.id) {
        setDisplaySpecies(s.name);
      }
      return s.name;
    });
  }
};

export const handleSetSiteName = (target, siteSegmentList, setDisplaySite) => {
  if (siteSegmentList) {
    siteSegmentList.data.map((s) => {
      if (Number(target) === s.siteId) {
        setDisplaySite(s.site);
      }
      return s.site;
    });
  }
};

export const handleSetSegmentName = (
  target,
  customSegmentList,
  setDisplaySegment
) => {
  if (customSegmentList) {
    customSegmentList.map((s) => {
      if (Number(target) === s.id) {
        setDisplaySegment(s.name);
      }
      return s.name;
    });
  }
};

export const statusChange = (
  checked,
  setDeadOrAlive,
  setStatus,
  setChangeTracker
) => {
  setChangeTracker(true);
  if (checked) {
    setDeadOrAlive(0);
    setStatus("Alive");
  } else {
    setDeadOrAlive(1);
    setStatus("Dead");
  }
};

export const handleSetObservationType = (
  e,
  observationList,
  setDisplayObserVationType
) => {
  if (observationList) {
    for (let x of observationList) {
      if (x.id === Number(e)) {
        setDisplayObserVationType(x.name);
      }
    }
  }
};

export const LongRangeCheck = (number) => {
  const num = Number(number);
  console.log(num, "long numb");

  if (isNaN(num) || num < -180 || num > 180) {
    return false;
  }
  return true;
};

export const LatRangeCheck = (number) => {
  const num = Number(number);
  if (isNaN(num) || num < -90 || num > 90) {
    return false;
  }
  return true;
};

export const handleSubmitCoords = (
  newLong,
  newLat,
  setNewLocalLat,
  setNewLocalLong,
  setShowCoordsBox,
  setIsCoordsSet,
  setChangeTracker,
  setIsInvalidCoords,
  setCoordsErrorMessage
) => {
  if (LongRangeCheck(newLong) && LatRangeCheck(newLat)) {
    setNewLocalLat(newLat);
    setNewLocalLong(newLong);
    setShowCoordsBox(false);
    setIsCoordsSet(true);
    setChangeTracker(true);
    setIsInvalidCoords(false);
  } else {
    if (!LatRangeCheck(newLat) && !LongRangeCheck(newLong)) {
      setCoordsErrorMessage(" Long and Lat Values are invalid");
    } else if (!LatRangeCheck(newLat)) {
      setCoordsErrorMessage("Latitude value is invalid");
    } else if (!LongRangeCheck(newLong)) {
      setCoordsErrorMessage("Longitude value is invalid");
    }
    setIsInvalidCoords(true);
  }
};
