import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DatePicker from "react-date-picker";
import { AiOutlineCalendar } from "react-icons/ai";
import useCreateValidation from "../../../../../../lib/createObs/useCreateValidation";
import { useCreateObservationStore } from "../../../../../../lib/createObs/useCreateObservationData";

function CreateNewNest({
  errors,
  setChangeTracker,
  siteSegmentList,
  customSegmentList,
}) {
  const {
    localNestIDValidation,
    localNestHighTideDistValidation,
    localCrawlWidthValidation,
    localNestCollectorValidation,
    localNestStatusValidation,
    localNestHealthValidation,
    localNestRelocationValidation,
    localNestRelocateSiteValidation,
    localNestFemalePresence,
    localSuspectedNestCreationDateValidation,
    localNestEstHatchDateValidation,
    localNestEstExhumDateValidation,

    setLocalNestIDValidation,
    setLocalNestHighTideDistValidation,
    setLocalNestStatusValidation,
    setLocalNestHealthValidation,
    setLocalNestRelocationValidation,
    setLocalNestFemalePresenceValidation,
    setLocalSuspectedNestCreationDateValidation,
    setLocalNestEstHatchDateValidation,
    setLocalNestEstExhumDateValidation,
    setLocalNestRelocateSiteValidation,
    setLocalCrawlWidthValidation,
  } = useCreateValidation();

  // Data management

  const {
    newDate,
    siteState,
    displaySite,
    suspectedNestCreationDate,
    isFemalePresent,
    setCrawlWidth,
    nestEstHatchDate,
    nestEstExhumDate,
    doesNewNestNeedRelocation,
    isNewNestIntact,
    setNestID,
    setNestHighTideDist,
    setNestStatus,
    setNestHealth,
    setNestRelocation,
    setSuspectedNestCreationDate,
    setNestEstHatchDate,
    setNestEstExhumDate,
    setIsFemalePresent,
    setRelocateSite,
    setRelocateSegment,
    setNumberOfEggs,
    setDoesNewNestNeedRelocation,
    setIsNewNestIntact,
  } = useCreateObservationStore();
  const { t } = useTranslation();
  console.log(siteState, "siteState");
  useEffect(() => {
    // Side effects based on changes in isNewNestIntact, doesNewNestNeedRelocation, or isFemalePresent
  }, [isNewNestIntact, doesNewNestNeedRelocation, isFemalePresent]);

  const exhumation = 65;
  const hatch = 60;

  useEffect(() => {
    if (isFemalePresent) {
      const curentDate = new Date(newDate);
      const exhumDate = new Date(curentDate); // Create a new date object
      exhumDate.setDate(exhumDate.getDate() + exhumation);
      setNestEstExhumDate(exhumDate);

      const hatchDate = new Date(curentDate); // Create another new date object
      hatchDate.setDate(hatchDate.getDate() + hatch);
      setNestEstHatchDate(hatchDate);
    } else {
      if (suspectedNestCreationDate) {
        const suspectedDate = new Date(suspectedNestCreationDate);
        const exhumDate = new Date(suspectedDate); // Create a new date object
        exhumDate.setDate(exhumDate.getDate() + exhumation);
        setNestEstExhumDate(exhumDate);

        const hatchDate = new Date(suspectedDate); // Create another new date object
        hatchDate.setDate(hatchDate.getDate() + hatch);
        setNestEstHatchDate(hatchDate);
        setLocalNestEstHatchDateValidation(false);
        setLocalNestEstExhumDateValidation(false);
      } else {
        setNestEstExhumDate("");
        setNestEstHatchDate("");
      }
    }
  }, [
    isFemalePresent,
    newDate,
    setNestEstExhumDate,
    setNestEstHatchDate,
    suspectedNestCreationDate,
  ]);

  useEffect(() => {
    if (siteState) {
      setRelocateSite(siteState);
    }
  }, [setRelocateSite, siteState]);
  // filter segment lsit
  const [filteredSegmentList, setFilteredSegmentList] = useState([]);
  useEffect(() => {
    if (siteSegmentList) {
      const filteredList = siteSegmentList.data.filter(
        (item) => item.siteId !== Number(siteState)
      );
      setFilteredSegmentList(filteredList);
    }
  }, [siteSegmentList, siteState]);

  return (
    <>
      {/* Nest ID */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {`${t("createNestFields.nestId")}`} <sup>*</sup>
        </div>
        <div className="col-7 modal__title__value">
          <input
            type="text"
            id="nest-id"
            name="nestID"
            className={`crud_select crud-input ${
              localNestIDValidation ? "is-invalid" : ""
            }`}
            style={{ width: "101%" }}
            onChange={(e) => {
              setNestID(e.target.value);

              if (e.target.value?.trim() !== "") {
                setLocalNestIDValidation(false);
                setChangeTracker(true);
              } else {
                setLocalNestIDValidation(true);
              }
              setLocalNestIDValidation(false);
            }}
          />
          {localNestIDValidation && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>

      {/* Nest High Tide Distance */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {`${t("createNestFields.distanceHideTide")}`}
        </div>
        <div className="col-7 modal__title__value">
          <div className="input-container">
            <input
              inputMode="numeric"
              type="number"
              id="high-tide-dist"
              name="highTideDist"
              className={`crud_select crud-input ${
                localNestHighTideDistValidation ? "is-invalid" : ""
              }`}
              style={{ width: "101%" }}
              onChange={(e) => {
                setNestHighTideDist(e.target.value);
                if (e.target.value?.trim() !== "") {
                  setLocalNestHighTideDistValidation(false);
                  setChangeTracker(true);
                } else {
                  setLocalNestHighTideDistValidation(true);
                  setChangeTracker(false);
                }
              }}
            />
            <span className="unit">(m)</span>
          </div>

          {localNestHighTideDistValidation && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>
      {/* localNestIDValidation */}
      {/* Crawl Width */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">{`${t(
          "createNestFields.crawlWidth"
        )}`}</div>
        <div className="col-7 modal__title__value">
          <div className="input-container">
            <input
              inputMode="numeric"
              type="number"
              id="crawl-width"
              name="crawlWidth"
              className={`crud_select crud-input ${
                localCrawlWidthValidation ? "is-invalid" : ""
              }`}
              style={{ width: "101%" }}
              onChange={(e) => {
                setCrawlWidth(e.target.value);

                if (e.target.value?.trim() !== "") {
                  setLocalCrawlWidthValidation(false);
                  setChangeTracker(true);
                } else {
                  setLocalCrawlWidthValidation(true);
                  setChangeTracker(false);
                }
              }}
            />
            <span className="unit">(cm)</span>
          </div>
          {localCrawlWidthValidation && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>

      {/* Nest Status */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">{`${t(
          "createNestFields.nestStatus"
        )}`}</div>
        <div className="col-7 modal__title__value">
          <select
            id="nest-status"
            name="nestStatus"
            className={`crud_select crud-input ${
              localNestStatusValidation ? "is-invalid" : ""
            }`}
            style={{ width: "101%" }}
            onChange={(e) => {
              setNestStatus(e.target.value);

              if (e.target.value?.trim() !== "") {
                setLocalNestStatusValidation(false);
                setChangeTracker(true);
              } else {
                setLocalNestStatusValidation(true);
                setChangeTracker(false);
              }
            }}
          >
            {/* <option disabled selected value="">
              Hatched
            </option> */}
            <option value="Unhatched">{`${t(
              "createNestFields.nestStatusOptions.unhatched"
            )}`}</option>
            <option value="Hatched">{`${t(
              "createNestFields.nestStatusOptions.hatched"
            )}`}</option>
            <option value="Exhumed">{`${t(
              "createNestFields.nestStatusOptions.exhumed"
            )}`}</option>
          </select>
          {localNestStatusValidation && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>

      {/* Nest Health */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">{`${t(
          "createNestFields.nestHealth"
        )}`}</div>
        <div className="col-7 modal__title__value">
          <select
            id="nest-health"
            name="nestHealth"
            className={`crud_select crud-input ${
              localNestHealthValidation ? "is-invalid" : ""
            }`}
            style={{ width: "101%" }}
            onChange={(e) => {
              const value = e.target.value;
              setNestHealth(value);
              setIsNewNestIntact(value === "Intact");

              if (value?.trim() !== "") {
                setLocalNestHealthValidation(false);
                setChangeTracker(true);
              } else {
                setLocalNestHealthValidation(true);
                setChangeTracker(false);
              }
            }}
          >
            <option value="Intact">{`${t(
              "createNestFields.nestHealthOptions.intact"
            )}`}</option>
            <option value="Partially lost">{`${t(
              "createNestFields.nestHealthOptions.partiallyLost"
            )}`}</option>
            <option value="Totally lost, Other">{`${t(
              "createNestFields.nestHealthOptions.totallyLost"
            )}`}</option>
          </select>
          {localNestHealthValidation && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>

      {/* Dynamic Reason for Loss */}
      {!isNewNestIntact && (
        <div className="row px-3 modal__obs__details__row mt-1">
          <div className="col-5 modal__detail__title">
            {`${t("createNestFields.reasonForLost")}`}
          </div>
          <div className="col-7 modal__title__value">
            <select
              id="reason-for-loss"
              name="reasonForLoss"
              className={`crud_select crud-input ${
                errors.reasonForLoss ? "is-invalid" : ""
              }`}
              style={{ width: "101%" }}
              onChange={(e) => {
                setNestRelocation(e.target.value);

                if (e.target.value?.trim() !== "") {
                  setLocalNestRelocationValidation(false);
                  setChangeTracker(true);
                } else {
                  setLocalNestRelocationValidation(true);
                  setChangeTracker(false);
                }
              }}
            >
              <option disabled selected value="">
                ({`${t("select")}`})
              </option>
              <option value="Flooding">
                {`${t("createNestFields.lostOptions.flooding")}`}
              </option>
              <option value="Other turtle">
                {" "}
                {`${t("createNestFields.lostOptions.otherTurtles")}`}
              </option>
              <option value="Predator">
                {" "}
                {`${t("createNestFields.lostOptions.predator")}`}
              </option>
              <option value="Unknown">
                {" "}
                {`${t("createNestFields.lostOptions.unknown")}`}
              </option>
              <option value="Other">
                {" "}
                {`${t("createNestFields.lostOptions.other")}`}
              </option>
            </select>
            {localNestRelocationValidation && (
              <p className="error">{`${t("required")}`}</p>
            )}
          </div>
        </div>
      )}

      {/* Nest Relocation */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {`${t("createNestFields.nestRelocate")}`}
        </div>
        <div className="col-7 modal__title__value">
          <select
            id="nest-relocation"
            name="nestRelocation"
            className={`crud_select crud-input `}
            style={{ width: "101%" }}
            onChange={(e) => {
              const needsRelocation = e.target.value === "true";
              setDoesNewNestNeedRelocation(needsRelocation);
              if (e.target.value?.trim() !== "") {
                setLocalNestRelocationValidation(true);
                setChangeTracker(true);
              } else {
                setLocalNestRelocationValidation(false);
                setChangeTracker(false);
              }
              setNestRelocation(needsRelocation);
            }}
          >
            <option value="false"> {`${t("createNestFields.no")}`}</option>
            <option value="true"> {`${t("createNestFields.yes")}`}</option>
          </select>
          {/* {localNestRelocationValidation && (
            <p className="error">{`${t("required")}`}</p>
          )} */}
        </div>
      </div>

      {/* Dynamic Relocation Details */}
      {doesNewNestNeedRelocation && (
        <>
          {/* Relocation Site*/}
          <div className="row px-3 modal__obs__details__row mt-1">
            <div className="col-5 modal__detail__title">
              {" "}
              {`${t("createNestFields.relocationSite")}`}
            </div>
            <div className="col-7 modal__title__value">
              <select
                className={`crud_select crud-input ${
                  errors.site ? "is-invalid" : ""
                }`}
                name="site"
                id="site"
                style={{
                  width: "101%",
                }}
                onChange={(e) => {
                  setRelocateSite(e.target.value);
                  if (e.target.value?.trim() !== "") {
                    setLocalNestRelocateSiteValidation(false);
                    setChangeTracker(true);
                  } else {
                    setLocalNestRelocateSiteValidation(true);
                    setChangeTracker(false);
                  }
                }}
              >
                <option disabled selected value="">
                  {displaySite}
                </option>

                {filteredSegmentList.map((s, i) => (
                  <option value={s.siteId} key={i}>
                    {s.site}
                  </option>
                ))}
              </select>

              {localNestRelocateSiteValidation && (
                <p className="error">{`${t("required")}`}</p>
              )}
            </div>
          </div>

          {/* Relocation Segment */}
          <div className="row px-3 modal__obs__details__row mt-1">
            <div className="col-5 modal__detail__title">
              {" "}
              {`${t("createNestFields.relocateSegment")}`}
            </div>
            <div className="col-7 modal__title__value">
              <select
                className={`crud_select crud-input ${
                  errors.segment ? "is-invalid" : ""
                }`}
                id="segment"
                name="segment"
                style={{
                  width: "101%",
                }}
                onChange={(e) => {
                  setRelocateSegment(e.target.value);
                  setChangeTracker(true);
                }}
              >
                <option disabled selected value="">
                  ({`${t("select")}`})
                </option>
                {customSegmentList &&
                  customSegmentList.map((s, i) => (
                    <option value={s.id} key={i}>
                      {s.name}
                    </option>
                  ))}
              </select>
              <p className="error">{errors.segment?.message}</p>
            </div>
          </div>

          {/* Number of Eggs Relocated */}
          <div className="row px-3 modal__obs__details__row mt-1">
            <div className="col-5 modal__detail__title">
              {`${t("createNestFields.numOfEggsRelocalise")}`}
            </div>
            <div className="col-7 modal__title__value">
              <input
                type="number"
                className={`crud_select crud-input ${
                  errors.eggsRelocated ? "is-invalid" : ""
                }`}
                id="eggs-relocated"
                name="eggsRelocated"
                style={{ width: "101%" }}
                onChange={(e) => {
                  setNumberOfEggs(e.target.value);
                  setChangeTracker(true);
                }}
              />
              <p className="error">{errors.eggsRelocated?.message}</p>
            </div>
          </div>
        </>
      )}

      {/* Female Presence */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {" "}
          {`${t("createNestFields.femalePresence")}`}
        </div>
        <div className="col-7 modal__title__value">
          <select
            id="is-female-present"
            name="isFemalePresent"
            className={`crud_select crud-input ${
              localNestFemalePresence ? "is-invalid" : ""
            }`}
            style={{ width: "101%" }}
            onChange={(e) => {
              setIsFemalePresent(e.target.value === "true");
              if (e.target.value?.trim() !== "") {
                setLocalNestFemalePresenceValidation(false);
                setChangeTracker(true);
              } else {
                setLocalNestFemalePresenceValidation(true);
                setChangeTracker(false);
              }
            }}
          >
            {/* <option disabled selected value="">
              (Select)
            </option> */}
            <option value="true">{`${t("createNestFields.yes")}`}</option>
            <option value="false">{`${t("createNestFields.no")}`}</option>
          </select>
          {localNestFemalePresence && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>

      {/* Dynamic Female Presence */}
      {!isFemalePresent && (
        <div className="row px-3 modal__obs__details__row mt-1">
          <div className="col-5 modal__detail__title">
            {"Suspected nest creation"}
          </div>
          <div className="col-7 modal__title__value nest">
            <DatePicker
              calendarIcon={<AiOutlineCalendar />}
              clearIcon={null}
              onChange={(date) => {
                setSuspectedNestCreationDate(date);
                setLocalSuspectedNestCreationDateValidation(false);
              }}
              // calendarClassName="calender__picker"
              className={`calender__picker`}
              value={suspectedNestCreationDate}
              required={true}
              format={"y/MM/dd"}
            />
            {localSuspectedNestCreationDateValidation && (
              <p className="error">{`${t("required")}`}</p>
            )}
          </div>
        </div>
      )}

      {/* Estimated Exhumation Date */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          {`${t("createNestFields.estimatedExhumDate")}`}
        </div>
        <div className="col-7 modal__title__value nest">
          <DatePicker
            calendarIcon={<AiOutlineCalendar />}
            clearIcon={null}
            onChange={(date) => {
              setNestEstExhumDate(date);
              setLocalNestEstExhumDateValidation(false);
            }}
            // calendarClassName="calender__picker"
            className={`calender__picker`}
            value={nestEstExhumDate}
            required={true}
            format={"y/MM/dd"}
          />

          {localNestEstExhumDateValidation && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>

      {/* Estimated Hatch Date */}
      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">{`${t(
          "createNestFields.estimatedHatchDate"
        )}`}</div>
        <div className="col-7 modal__title__value nest">
          <DatePicker
            calendarIcon={<AiOutlineCalendar />}
            clearIcon={null}
            onChange={(date) => {
              setNestEstHatchDate(date);
              setLocalNestEstHatchDateValidation(false);
            }}
            // calendarClassName="calender__picker"
            className={`calender__picker`}
            value={nestEstHatchDate}
            required={true}
            format={"y/MM/dd"}
          />

          {localNestEstHatchDateValidation && (
            <p className="error">{`${t("required")}`}</p>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateNewNest;
