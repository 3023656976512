import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import ModalMarker from "../../map/leafletMap/ModalMarker";
import "../style.css";
import SetMap from "./SetMap";
const ModalsMap = ({
  newLocalLat,
  newLocalLong,
  newLat,
  newLong,
  displayObserVationType,
}) => {
  return (
    <MapContainer
      center={[newLocalLat && newLocalLat, newLocalLong && newLocalLong]}
      zoom={12}
      scrollWheelZoom={true}
      zoomControl={false}
      layer-type="base"
      style={{ width: "100%", height: "100%", zIndex: "1" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {newLat && newLong && (
        <SetMap
          location={[newLat, newLong]}
          lat={newLat}
          long={newLong}
          typeObservation={displayObserVationType}
        />
      )}

      <ModalMarker observation={null} lat={newLocalLat} long={newLocalLong} />
    </MapContainer>
  );
};

export default ModalsMap;
