import "./mapslider.css";
import { ReactComponent as PlaceholderImage } from "../../../assets/images/no-image.svg";
// import Draft from '../'
import { formatDate } from "../../../utils/DateFormater";
import { observationStyle } from "../../../utils/TypeObsStyle";
import SidebarPopup from "./SidebarPopup";
import {
  changeMarker,
  deleteObservaion,
  deleteObservationLocally,
  openPopup,
  panTracker,
} from "../../../actions/projectActions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import EditObservation from "../../modals/observations/editObs/EditObservation";
import ImageCarosel from "../../modals/ImageCarosel";
import DetailModal from "../../modals/observations/detailObs/DetailModal";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { setPan, setPanTest } from "../../../services/rxjs";
import { useTranslation } from "react-i18next";
import { useTranslateObsType } from "../../../utils/useTranslateObservationType";
import ConfirmationModal from "../../modals/ConfirmationModal";

const ObstTranslated = ({ obstype }) => {
  const translatedObsType = useTranslateObsType(obstype);
  return (
    <div
      style={{
        fontSize: "0.7rem",
      }}
    >
      {translatedObsType}
    </div>
  );
};

function MapSidebar({ observationList }) {
  const [observation, setObservation] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const setNewMarker = useSelector((state) => state.changeSelectedMarker);
  // const { markerDetails } = setNewMarker;
  const [singleObsIndex, setSingleObsIndex] = useState();

  //
  const handleHideModal = () => {
    setShowProjectModal(false);
  };

  //edit modal hide and show state and function
  const [showEditModal, setShowEditModal] = useState(false);

  // caroel
  const [showCarosel, setShowCarosel] = useState(false);
  const handleShowImageCarosel = () => {
    setShowCarosel(true);
    setShowProjectModal(false);
  };
  const hideImageCarosel = () => {
    setShowCarosel(false);
  };

  const handleHideEditModal = () => {
    setShowEditModal(false);
  };
  const handleOpenEditModal = () => {
    setShowEditModal(true);
    setShowProjectModal(false);
  };

  const [showProjectModal, setShowProjectModal] = useState(false);

  // delete modal state and functions
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [observaionId, setObservaionId] = useState();

  const handleHideConfirmationModal = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmAction = () => {
    dispatch(deleteObservaion(observaionId));
    dispatch(deleteObservationLocally([observaionId]));
    handleHideConfirmationModal();
  };

  // deleteObsFromView

  const [deleteObsFromView, setDeleteObsFromView] = useState(false);
  const handleDeleteObsFromView = () => {
    setDeleteObsFromView(!deleteObsFromView);
  };
  const closeConfirm = () => {
    setDeleteObsFromView(false);
  };
  const closeConfirm2 = () => {
    setShowConfirmModal(false);
  };

  return (
    <>
      {observationList && observationList?.length !== 0 ? (
        <>
          {observationList.map((project, index) => (
            // projectTest.map((project, index) => (
            <div key={index}>
              <div>
                <div
                  className="px-1 pb-1 pt-0  "
                  onClick={() => {
                    dispatch(changeMarker({ id: project.id, index: index }));
                    dispatch(openPopup({ id: "", index: "" }));
                    dispatch(panTracker({ isPanning: true }));
                    setPan(true);
                    setPanTest(true);
                    setSingleObsIndex(index);
                    setObservation(project);
                  }}
                >
                  <div
                    id="obs-item"
                    className={`obs-item row   ${
                      singleObsIndex === index ? "selected__observation" : ""
                    }`}
                    onDoubleClick={() => {
                      setShowProjectModal(true);
                    }}
                  >
                    {project.images?.img1 !== "NULL" ? (
                      <div
                        className="col-3 obs-img h-100"
                        style={{
                          backgroundImage: `url(${project.images?.img1})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    ) : (
                      <div
                        className="col-3 obs-img h-100 "
                        style={{
                          // backgroundImage: `url(${noImage})`,
                          // backgroundRepeat: "no-repeat",
                          // // backgroundPosition: "center center",
                          backgroundSize: "100% 100%",
                        }}
                      >
                        <PlaceholderImage className="no-image" />
                      </div>
                    )}
                    <div className=" col-9 h-100 position-relative p-2">
                      <span className="position-absolute pointer more">
                        <SidebarPopup
                          project={project}
                          totalObservationList={observationList}
                        />
                      </span>
                      <div className="obs-name mp-header">Obs {project.id}</div>
                      <span
                        className="obs-color-type-01 obs-type d-inline-block"
                        style={
                          project.TypeObservation === "Threat"
                            ? observationStyle.Threat
                            : project.TypeObservation === "Crawl"
                            ? observationStyle.Crawls
                            : project.TypeObservation === "Animal"
                            ? observationStyle.Animal
                            : project.TypeObservation === "Flash Observation"
                            ? observationStyle.FlashObs
                            : project.TypeObservation === "Signs of presence"
                            ? observationStyle.SignsPresence
                            : project.TypeObservation === "Female Encounter"
                            ? observationStyle.FemaleEn
                            : project.TypeObservation === "Nest"
                            ? observationStyle.Nest
                            : observationStyle.noStyle
                          //  styles.noStyle
                        }
                      >
                        <ObstTranslated obstype={project.TypeObservation} />
                      </span>
                      <div className="obs-desc  mp-location">
                        {t("listview.table.site")}: {project.site}
                      </div>
                      <div className="obs-date">
                        <span className="pe-sm-2">
                          <img
                            src="assets/elements/images/icon/calander.svg"
                            alt=""
                          />
                        </span>
                        <span className="obs-cont-date">
                          {formatDate(project.date?.date)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div
          style={{
            margin: "15rem auto",
            textAlign: "center",
            // fontSize: ".9rem",
            alignItems: "center",
          }}
        >
          {t("listview.customComponents.noObs")}
          {/* {sirenInterNationalId !== Number(localProjectId)
            ? " No observations found"
            : ""} */}
        </div>
      )}

      {showProjectModal && (
        <DetailModal
          editData={observation}
          message={"hello"}
          closeDetailModal={handleHideModal}
          openIsEditModal={handleOpenEditModal}
          handleShowImageCarosel={handleShowImageCarosel}
        />
      )}
      {showEditModal && (
        <EditObservation
          editData={observation}
          closeIsEditModal={handleHideEditModal}
          // index={singleObsIndex}
        />
      )}

      {showCarosel && (
        <ImageCarosel
          // handleShowImageCarosel={handleShowImageCarosel}
          hideImageCarosel={hideImageCarosel}
          images={observation.images}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineQuestionCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={"Delete Items?"}
          details={"items will be deleted. This action cannot be undone"}
          btn1Text={"Cancel"}
          btn2Text={"Delete"}
          color={"red"}
          handleHideConfirmationModal={handleHideConfirmationModal}
          handleConfirmAction={handleConfirmAction}
          closeConfirm={closeConfirm2}
        />
      )}
      {deleteObsFromView && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineQuestionCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={"Delete Items?"}
          details={"items will be deleted. This action cannot be undone"}
          btn1Text={"Cancel"}
          btn2Text={"Delete"}
          color={"red"}
          handleHideConfirmationModal={handleDeleteObsFromView}
          closeConfirm={closeConfirm}
          handleConfirmAction={handleConfirmAction}
        />
      )}
    </>
  );
}
export default MapSidebar;
