import React from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-date-picker";
import "react-time-picker/dist/TimePicker.css";

import { AiOutlineCalendar } from "react-icons/ai";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "../style.css";
import { useCreateObservationStore } from "../../../lib/createObs/useCreateObservationData";
const DateTimeSelector = ({ errors, setChangeTracker }) => {
  const { newDate, currentTime, setCurrentTime, setNewDate } =
    useCreateObservationStore();
  const onChangeTime = (time, timeString) => {
    setCurrentTime(timeString);
    setChangeTracker(true);
  };
  dayjs.extend(customParseFormat);
  const format = "HH:mm";
  const { t } = useTranslation();
  return (
    <div className="row px-3 modal__obs__details__row mt-1">
      <div className="col-5 modal__detail__title">
        {t("dateTime")}
        <sup>*</sup>
      </div>

      <div className="col-7 modal__title__value d-flex">
        <div className="d-flex  col-12">
          <div
            className="col-6 new__date_container"
            style={{
              width: "50%",
            }}
          >
            <DatePicker
              calendarIcon={<AiOutlineCalendar />}
              clearIcon={null}
              onChange={setNewDate}
              // calendarClassName="calender__picker"
              className={`calender__picker`}
              value={newDate}
              required={true}
              format={"y/MM/dd"}
            />

            <p className="error">{errors.date?.message}</p>
          </div>

          <div
            className=" col-6 new__date_container"
            style={{
              width: "50%",
            }}
          >
            <TimePicker
              className="time-picker-input"
              onChange={onChangeTime}
              defaultValue={dayjs(`${currentTime}`, format)}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              format={format}
            />

            <p className="error">{errors.time?.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateTimeSelector;
