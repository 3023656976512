import React, { useEffect, useRef, useState } from "react";
import "../../pages/admin/admin.css";
import "./Header.css";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import close from "../../assets/images/icon/close.svg";
import list2 from "../../assets/images/icon/list2.svg";
import obsList from "../../assets/images/icon/obslist.svg";
import mapView from "../../assets/images/icon/mapview.svg";
import question from "../../assets/images/icon/question.svg";
import newObs from "../../assets/images/icon/newobs.svg";
import notif2 from "../../assets/images/icon/notif2.svg";
import account from "../../assets/images/icon/account.svg";
import mobileLogout from "../../assets/images/icon/mobileLogout.svg";
import sanduich from "../../assets/images/icon/sanduich.svg";
import dashboard from "../../assets/images/icon/dashboard.svg";
import list from "../../assets/images/icon/list.svg";
import caret_down from "../../assets/images/caret_down.png";
import obslist from "../../assets/images/icon/obslist.svg";
import validObsIcon from "../../assets/images/icon/validObsIcon.svg";
import ColorpatrolIcon from "../../assets/images/icon/coloredPatrolIcon.svg";
import WhitepatrolIcon from "../../assets/images/icon/whiePatrolIcon.svg";
import mapview from "../../assets/images/icon/mapview.svg";
import notifications from "../../assets/images/icon/notifications.svg";

import frenchFlag from "../../assets/images/landingFrenchFlag.png";
import englishFlag from "../../assets/images/landingEnglishFlag.png";
import selectIcon from "../../assets/images/selected.png";
import profil from "../../assets/images/icon/profil.svg";
import { useFetchProjectList } from "../../queries/useMapQueriesHook";
import { useDispatch, useSelector } from "react-redux";
// "react-simple-phone-input": "^5.1.9",
import {
  changeMarker,
  refetchObs,
  setData,
  userProjectRoleAction,
} from "../../actions/projectActions";
import { modpro$, setProjectRole } from "../../services/rxjs";
import axios from "axios";
import { API_URL as API } from "../../services/API";
import { logout } from "../../actions/userActions";
import CreateObservation from "../modals/observations/createObs/CreateObservation";
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";
import { usePatrolVariables } from "../../lib/usePatrolVariable";

const Header = () => {
  const { setCurrentPage, setTotalPageNumber } = usePatrolVariables(
    (state) => ({
      setTotalPageNumber: state.setTotalPageNumber,
      setCurrentPage: state.setCurrentPage,
    })
  );
  // Translate switcher
  const { t, i18n } = useTranslation();
  const languageMenuRef = useRef();

  useEffect(() => {
    const hideMenus = (e) => {
      if (
        languageMenuRef.current &&
        !languageMenuRef.current.contains(e.target)
      ) {
        setLanguageHidden(true);
      }
    };

    document.addEventListener("click", hideMenus);

    return () => {
      document.removeEventListener("click", hideMenus);
    };
  }, []);
  const [language, setLanguage] = useState("English");
  const [languageHidden, setLanguageHidden] = useState(true);
  const handleLanguageChange = (val) => {
    i18n.changeLanguage(val);
    if (val === "fr") {
      localStorage.setItem("localLangVal", "French");
    } else {
      localStorage.setItem("localLangVal", "English");
    }
  };

  const localLangVal = localStorage.getItem("localLangVal");
  useEffect(() => {
    if (localLangVal === "French") {
      i18n.changeLanguage("fr");
      setLanguage("French");
    } else {
      i18n.changeLanguage("en");
      setLanguage("English");
    }
  }, [i18n, localLangVal]);

  function getCurrentURL() {
    return window.location.pathname;
  }
  useEffect(() => {
    // menu list hide and show
    const menuView = document.querySelector(".menuView");
    const menuContent = document.querySelector(".menu-content");

    const closeMenu = () => {
      menuContent.classList.add("d-none");
    };

    const handleClickOutsideMenu = (event) => {
      if (
        !menuContent.contains(event.target) &&
        !menuView.contains(event.target)
      ) {
        closeMenu();
      }
    };

    menuView.addEventListener("click", function (e) {
      e.stopPropagation();
      const leftOffset = menuView.offsetLeft;
      menuContent.style.top = "50px";
      menuContent.style.left = `${leftOffset}px`;
      menuContent.classList.remove("d-none");
    });

    document.addEventListener("click", handleClickOutsideMenu);

    return () => {
      document.removeEventListener("click", handleClickOutsideMenu);
    };
  }, []);

  // get project list
  const {
    projectList,
    refetchProjects,
    isLoading,

    projectRefetchLoading,
  } = useFetchProjectList();

  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [projectName, setProjectName] = useState(`${t("project")}`);
  const [projectId, setProjectId] = useState();
  const [modpro, setModpro] = useState();
  // local storage change
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  const dispatch = useDispatch();
  const handleSetUserRole = (singleProject) => {
    if (singleProject.role === "admin") {
      dispatch(userProjectRoleAction({ role: true }));
      localStorage.setItem("userPrRole", true);
      setIsUserAdmin(true);
    } else {
      dispatch(userProjectRoleAction({ role: false }));
      localStorage.setItem("userPrRole", false);
      setIsUserAdmin(false);
    }
  };
  useEffect(() => {
    // setTime
    modpro$.subscribe((user) => setModpro(user));
    let projectId = localStorage.getItem("projectId");
    dispatch(setData(projectId));
    let projectName = localStorage.getItem("projectName");
    // handleFetchObs(projectId);
    localStorage.setItem("projectList", JSON.stringify(projectList));

    let userRoleFromStorage = localStorage.getItem("userPrRole");
    if (userRoleFromStorage === "true") {
      setIsUserAdmin(true);
      dispatch(userProjectRoleAction({ role: true }));
    } else {
      setIsUserAdmin(false);
      dispatch(userProjectRoleAction({ role: false }));
    }

    if (projectList && projectList.data.length !== 0) {
      if (!projectId) {
        let obsId = projectList && projectList.data[0].id;

        const getProjts = async () => {
          const userInfo = JSON.parse(localStorage.getItem("userInfo"));

          try {
            const token = userInfo.data.token;
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };

            let response = await axios
              .get(API + "api/user/project", config)
              .then((res) => res.data);

            if (
              response.data.length !== 0 &&
              response.data[0].role === "admin"
            ) {
              dispatch(userProjectRoleAction({ role: true }));
              localStorage.setItem("userPrRole", true);
              localStorage.setItem("role", response.data[0].role);

              setIsUserAdmin(true);
            } else {
              dispatch(userProjectRoleAction({ role: false }));
              localStorage.setItem("userPrRole", false);

              setIsUserAdmin(false);
            }
            // handleFetchObs(response.data[0].id);
            localStorage.setItem("projectId", response.data[0].id);
            dispatch(setData(response.data[0].id));
            let localProjectName = response.data[0].name;

            localStorage.setItem(
              "selectedProject",
              JSON.stringify(response.data[0])
            );
            localStorage.setItem("projectName", localProjectName);
          } catch (error) {
            console.log(error);
          }
        };
        getProjts();

        // set userRole

        localStorage.setItem("projectId", obsId);
        let localProjectName = projectList && projectList.data[0].name;
        localStorage.setItem("projectName", localProjectName);
        localStorage.setItem("selectedProject", projectList.data[0]);

        // localStorage.setItem(
        //   "selectedProject",
        //   JSON.stringify(projectList.data[0])
        // );

        setProjectRole(
          JSON.stringify(projectList?.data[0]),
          projectList.data[0].role
        );

        setProjectId(obsId);
        setProjectName(localProjectName);
      } else {
        setProjectId(projectId);
        // handleFetchObs(projectId);
        dispatch(setData(projectId));
        setProjectName(projectName);
      }
    }

    // }, 2000);
  }, [
    projectList,
    projectName,
    projectId,
    localProjectId,
    modpro,
    dispatch,
    refetchProjects,
  ]);

  const [currentProjectId, setCurrentProjectId] = useState();
  useEffect(() => {
    refetchProjects();
    setCurrentProjectId(projectId);
    // handleFetchObs(projectId);
  }, [localProjectId, projectList, projectId, refetchProjects]);

  const handleStorageEvent = (singleProject) => {
    let currentProject = JSON.stringify(singleProject);
    localStorage.setItem("selectedProject", currentProject);
    setProjectRole(currentProject, singleProject.role);

    localStorage.setItem("projectId", singleProject.id);
    localStorage.setItem("projectName", singleProject.name);

    setProjectName(singleProject.name);
    setProjectId(singleProject.id);

    dispatch(setData(singleProject.id));
  };

  // get project list end

  const [currentView, setCurrentView] = useState("");
  const [viewIcon, setViewIcon] = useState(null);
  const url = getCurrentURL();

  useEffect(() => {
    if (url === "/map") {
      setCurrentView(`${t("mapView")}`);
      setViewIcon(list);
    } else if (url === "/listview-unvalidate") {
      setCurrentView(`${t("unvalidView")}`);
      setViewIcon(list);
    } else if (url === "/listview-validate") {
      setCurrentView(`${t("listView")}`);
      setViewIcon(validObsIcon);
    } else if (
      url === "/admin" ||
      url === "/dashboard" ||
      url === "/dashboard/"
    ) {
      setViewIcon(list);
      setCurrentView(`${t("selectView")}`);
    } else if (url === "/patrols") {
      setViewIcon(WhitepatrolIcon);
      setCurrentView(`${t("patrolView")}`);
    } else if (url.slice(0, 29) === "/patrols/observations/unvalid") {
      setCurrentView(`${t("unvalidPatrolObs")}`);
      setViewIcon(WhitepatrolIcon);
    } else if (url.slice(0, 27) === "/patrols/observations/valid") {
      setCurrentView(`${t("validPatrolObs")}`);
      setViewIcon(WhitepatrolIcon);
    }
  }, [url, language, t]);

  const selectProject = (id, index) => {
    setProjectName(projectList.data[index].name);

    dispatch(changeMarker({ id: "", index: "" }));
  };

  // show project list

  const projectTitleRef = useRef(null);
  const dashBoardRef = useRef(null);
  const targetElementRef = useRef(null);
  const [projectListTop, setProjectListTop] = useState(-1000);
  const [initialPLPosition, setInitialPLPosition] = useState(null);

  const handleShowProjectList = () => {
    if (projectListTop !== -1000) {
      setProjectListTop(-1000);
    } else {
      setProjectListTop(initialPLPosition);
    }
  };

  const handleHideProjectList = () => {
    if (projectListTop !== -1000) {
      setProjectListTop(-1000);
    }
  };

  useEffect(() => {
    const projectTitle = projectTitleRef.current;
    const targetElement = targetElementRef.current;

    const updatePosition = () => {
      if (projectTitle && targetElement) {
        const { top, left, width } = projectTitle.getBoundingClientRect();

        setInitialPLPosition(top + projectTitle.offsetHeight + 7);
        targetElement.style.left = `${left + 12}px`;
        targetElement.style.width = `${width}px`;
      }
    };

    updatePosition();
    window.addEventListener("resize", updatePosition);

    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, []);

  const [toggleMobileMenu, setToggleMobileMenu] = useState(-900);
  const handleToggleMobileMenu = () => {
    if (toggleMobileMenu === -900) {
      setToggleMobileMenu(0);
    } else {
      setToggleMobileMenu(-900);
    }
  };

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleHideModalCreateModal = () => {
    setShowCreateModal(false);
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div
      onClick={() => {
        handleHideProjectList();
      }}
    >
      <div
        className="mobileMenu__container"
        style={{
          left: `${toggleMobileMenu}px`,
        }}
      >
        <div
          className="d-flex flex-column flex-shrink-0 menu-list position-absolute w-100 h-100"
          style={{ zIndex: "20" }}
        >
          <div className="mobile__menuTop">
            <div className="menu__mobile__text">Menu</div>
            <div
              className=" p-2"
              onClick={() => {
                handleToggleMobileMenu();
              }}
            >
              <span className="close-menu">
                <span className="pe-sm-2">
                  <Image src={close} alt="" style={{ height: "17px" }} />
                </span>
              </span>
            </div>
          </div>
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <a
                className="nav-link btn btn-toggle-men nivo1 "
                data-bs-toggle="collapse"
                data-bs-target="#menu-obs-collapse"
                data-name="beach-collapse"
                aria-expanded="false"
                href="#"
              >
                <div className="mobile__menu__items">
                  <span className="element-menu-top ps-4">
                    <Image
                      src={list2}
                      alt=""
                      className="mobile__icon"
                      width={80}
                    />
                  </span>
                  <span className="mx-2">Observations</span>
                </div>
              </a>

              <div className="collapse" id="menu-obs-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal small ">
                  <li className="btn-toggle-item">
                    <div className="mobile__menu__items sub">
                      <NavLink to="/listview-validate">
                        <span className="ms-2 me-sm-1">
                          <Image src={obsList} alt="" />
                        </span>

                        <span className="mx-2">{t("listView")}</span>
                      </NavLink>
                    </div>
                  </li>
                  <li className="btn-toggle-item">
                    <div className="mobile__menu__items sub">
                      <NavLink to="/map">
                        <span className="ms-3 me-sm-1">
                          <Image src={mapView} alt="" />
                        </span>{" "}
                        <span className="mx-2">{t("mapView")}</span>
                      </NavLink>
                    </div>
                  </li>
                  {isUserAdmin && (
                    <li className="btn-toggle-item">
                      <div className="mobile__menu__items sub">
                        <NavLink to="listview-unvalidate">
                          <span className="ms-4 ps-1 me-sm-1">
                            <Image src={question} alt="" />
                          </span>

                          <span className="mx-2"> {t("unvalidView")}</span>
                        </NavLink>
                      </div>
                    </li>
                  )}
                  <li className="btn-toggle-item">
                    <div
                      className="mobile__menu__items sub"
                      onClick={() => {
                        setShowCreateModal(!showCreateModal);
                        handleToggleMobileMenu();
                      }}
                    >
                      <a href="#">
                        <span className="ms-3 ps-5 me-sm-1">
                          <Image src={newObs} alt="" />
                        </span>
                        <span className="mx-2"> {t("newObs")}</span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="checker empty"></div>
            </li>
            {/* <hr style={{ margin: "0px" }} /> */}
            <li className="nav-item ">
              <NavLink to="#" className="nav-link btn btn-toggle-men nivo1 ">
                <div className="mobile__menu__items">
                  <span className="element-menu-top ps-2">
                    <Image
                      src={notif2}
                      alt=""
                      className="mobile__icon"
                      width={80}
                    />
                  </span>
                  <span className="mx-2">Notification</span>
                </div>
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink
                to="/admin"
                className="nav-link btn btn-toggle-men nivo1"
              >
                <div className="mobile__menu__items">
                  <span className="element-menu-top ps-2">
                    <Image
                      src={account}
                      alt=""
                      className="mobile__icon"
                      width={80}
                    />
                  </span>
                  <span className="mx-2">{t("myAccount")}</span>
                </div>
              </NavLink>
            </li>
            <li
              className="nav-item "
              onClick={() => {
                logoutHandler();
                localStorage.removeItem("userPrRole");
              }}
            >
              <a className="nav-link btn btn-toggle-men nivo1 " href="#">
                <div className="mobile__menu__items">
                  <span className="element-menu-top ps-2 ">
                    <Image
                      src={mobileLogout}
                      alt=""
                      className="mobile__icon"
                      width={80}
                    />
                  </span>
                  <span className="mx-2 pe-4 me-4">{t("logout")}</span>
                </div>
              </a>
            </li>
            <hr style={{ margin: "0px" }} />
          </ul>
        </div>
      </div>

      <div
        className="row header position-relative "
        style={{
          zIndex: "222",
        }}
      >
        {/*End mobile Header */}

        <span
          className="menu-mobile p-4 element-menu-top position-absolute show-menu-sanduich"
          onClick={() => {
            handleToggleMobileMenu();
          }}
        >
          <Image src={sanduich} alt="" className="hambuger__menu" />
        </span>

        {/* col-md-2 */}
        <div className="col logo-center">
          <NavLink to={"/"}>
            <Image src={logo} alt="" className="logo" />
          </NavLink>
        </div>

        <div className="col-md-10  hide-men">
          <div className="menu-button-top f-bold h-100 mh-100 text-right ">
            {/*=========== Project list ===============*/}

            {!isLoading && projectList?.data.length !== 0 ? (
              <a
                className={`element-menu-top project_title px-4 me-2 noDeco mh-100 pointer position-relative dropdown-toggle bobserv py-2 `}
                id="btnGroupDrop1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                href="#"
                ref={projectTitleRef}
                onClick={() => {
                  handleShowProjectList();
                }}
              >
                <span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 27 25"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${
                      projectList?.data.length > 0 ? "wIco" : "noPro"
                    }`}
                  >
                    <path d="M5.66594 6.33196V2.33152C5.66594 1.97786 5.80643 1.63869 6.05651 1.38861C6.30659 1.13854 6.64576 0.998047 6.99942 0.998047H15.5523L18.2193 3.665H25.6681C26.0218 3.665 26.3609 3.80549 26.611 4.05557C26.8611 4.30565 27.0016 4.64482 27.0016 4.99848V18.3333C27.0016 18.6869 26.8611 19.0261 26.611 19.2762C26.3609 19.5263 26.0218 19.6667 25.6681 19.6667H21.6677V23.6672C21.6677 24.0208 21.5272 24.36 21.2771 24.6101C21.027 24.8602 20.6879 25.0007 20.3342 25.0007H1.66551C1.31185 25.0007 0.972673 24.8602 0.722598 24.6101C0.472522 24.36 0.332031 24.0208 0.332031 23.6672V7.66544C0.332031 7.31178 0.472522 6.9726 0.722598 6.72253C0.972673 6.47245 1.31185 6.33196 1.66551 6.33196H5.66594ZM5.66594 8.99892H2.99899V22.3337H19.0007V19.6667H5.66594V8.99892ZM8.3329 3.665V16.9998H24.3346V6.33196H17.1152L14.4482 3.665H8.3329Z" />
                  </svg>

                  <span className="px-2 header_project_label">
                    {t("project")} :
                  </span>
                </span>
                <span
                  className={`mx-1 proName ${
                    projectList.data.length > 0 ? "" : "noProject"
                  }`}
                >
                  {projectName && projectName !== "undefined"
                    ? projectName
                    : "Project list Loading..."}
                </span>

                <span className="hide-sort-arrow">
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`mb-1 ${
                      projectList.data.length > 0 ? "wIco" : "noPro"
                    }`}
                  >
                    <path d="M0 0.796876L8 9.20267L16 0.796875L0 0.796876Z" />
                  </svg>
                </span>
              </a>
            ) : (
              <a
                className={`element-menu-top project_title px-4 me-2 noDeco mh-100 pointer position-relative dropdown-toggle bobserv py-2 projectLitLoading`}
                ref={projectTitleRef}
                href="#"
              >
                <span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 27 25"
                    xmlns="http://www.w3.org/2000/svg"
                    className="noPro mb-1"
                  >
                    <path d="M5.66594 6.33196V2.33152C5.66594 1.97786 5.80643 1.63869 6.05651 1.38861C6.30659 1.13854 6.64576 0.998047 6.99942 0.998047H15.5523L18.2193 3.665H25.6681C26.0218 3.665 26.3609 3.80549 26.611 4.05557C26.8611 4.30565 27.0016 4.64482 27.0016 4.99848V18.3333C27.0016 18.6869 26.8611 19.0261 26.611 19.2762C26.3609 19.5263 26.0218 19.6667 25.6681 19.6667H21.6677V23.6672C21.6677 24.0208 21.5272 24.36 21.2771 24.6101C21.027 24.8602 20.6879 25.0007 20.3342 25.0007H1.66551C1.31185 25.0007 0.972673 24.8602 0.722598 24.6101C0.472522 24.36 0.332031 24.0208 0.332031 23.6672V7.66544C0.332031 7.31178 0.472522 6.9726 0.722598 6.72253C0.972673 6.47245 1.31185 6.33196 1.66551 6.33196H5.66594ZM5.66594 8.99892H2.99899V22.3337H19.0007V19.6667H5.66594V8.99892ZM8.3329 3.665V16.9998H24.3346V6.33196H17.1152L14.4482 3.665H8.3329Z" />
                  </svg>
                </span>
                <span className="mx-1 noProject">{t("project")}</span>
                <span className="hide-sort-arrow">
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    xmlns="http://www.w3.org/2000/svg"
                    className="noPro mb-1"
                  >
                    <path d="M0 0.796876L8 9.20267L16 0.796875L0 0.796876Z" />
                  </svg>
                </span>
              </a>
            )}

            {/*=====End project list=========================== */}
            <NavLink
              to="/dashboard"
              className={`element-menu-top btn__hover medium px-2  noDeco mh-100 pointer py-2 ${
                url === "/dashboard" ? "dashboardActive" : ""
              }`}
              ref={dashBoardRef}
            >
              <span>
                <Image src={dashboard} alt="" className="ico" />
              </span>
              <span
                className="mx-2"
                style={{
                  paddingTop: "1px",
                }}
              >
                {t("dashBoard")}
              </span>
            </NavLink>
            <a
              className="element-menu-top large ps-3 py-2 me-2 noDeco mh-100 pointer position-relative dropdown-toggle menuView"
              id="btnGroupDrop1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              href="#"
            >
              <span>
                <Image
                  src={viewIcon ? viewIcon : list}
                  // className={`${
                  //   projectList.data.length > 0 ? "wIco" : "noPro"
                  // }`}
                  width="20"
                  height="20"
                  alt="icon"
                />
              </span>
              <span
                className="mx-2 "
                style={{
                  paddingTop: "1px",
                }}
              >
                {currentView}
              </span>
              <span
                className="hide-sort-arrow "
                style={{
                  paddingRight: "10px",
                }}
              >
                <Image src={caret_down} alt="caret" />
              </span>
            </a>

            <div
              className="menu-content regular d-none show-block"
              style={{ zIndex: "10" }}
            >
              <NavLink
                to={`/listview-validate`}
                className="d-block men-action-item"
                onClick={() => {
                  setCurrentView("List View ");
                }}
              >
                <span className="ms-2 me-sm-1 ">
                  <Image src={obslist} alt="" />
                </span>{" "}
                {t("listView")}
                <span className={`cont-check-action active-menu`}></span>
              </NavLink>
              <NavLink to={`/map`} className="d-block men-action-item">
                <span className="ms-2 me-sm-1">
                  <Image src={mapview} alt="" />
                </span>{" "}
                {t("mapView")}
                <span className={`cont-check-action active-menu`}></span>
              </NavLink>

              {isUserAdmin && (
                <NavLink
                  to={{
                    pathname: "/listview-unvalidate",
                    state: { dashboardDates: [] },
                  }}
                  // href="list_unvalidated.html"
                  className="d-block men-action-item "
                >
                  <span className="ms-2 me-sm-1">
                    <Image src={question} alt="" />
                  </span>
                  {t("unvalidView")}
                  <span className={`cont-check-action active-menu  `}></span>
                </NavLink>
              )}
              <NavLink to={`/patrols`} className="d-block men-action-item">
                <span className="ms-2 me-sm-1">
                  <Image src={ColorpatrolIcon} alt="" />
                </span>{" "}
                {t("patrolView")}
                <span className={`cont-check-action active-menu`}></span>
              </NavLink>
            </div>
            <div
              onClick={() => setLanguageHidden((state) => !state)}
              ref={languageMenuRef}
              className="header__lang__container"
            >
              <span
                className="element-menu-top  py-2  pointer"
                style={{
                  padding: "0px 10px",
                }}
              >
                <Image
                  alt="flag"
                  className="ico mx-2  me-sm-2 pro profile__icon "
                  width={24}
                  height={16}
                  src={language === "French" ? frenchFlag : englishFlag}
                />
                <span className="mx-1 label">
                  {" "}
                  {language === "French" ? t("french") : t("english")}
                </span>

                <Image
                  style={{
                    paddingLeft: "2px ",
                  }}
                  className="lang_caret"
                  src={caret_down}
                  alt="caret"
                />
              </span>

              <div
                className={`langSelector  ${
                  languageHidden ? "dropDownHidden" : ""
                }`}
              >
                <div
                  onClick={() => {
                    handleLanguageChange("en");
                  }}
                >
                  {t("english")}
                  {language === "English" && (
                    <Image src={selectIcon} alt="check" className="ps-2" />
                  )}
                </div>
                <div
                  onClick={() => {
                    handleLanguageChange("fr");
                    setLanguage("French");
                  }}
                >
                  {t("french")}
                  {language === "French" && (
                    <Image src={selectIcon} alt="check" className="ps-2" />
                  )}
                </div>
              </div>
            </div>
            <a
              className="element-menu-top px-3   noDeco mh-100 pointer"
              href="#"
            >
              <span>
                <Image
                  src={notifications}
                  alt=""
                  className="ico profile__icon"
                />
              </span>
            </a>

            <NavLink
              to={`/admin`}
              // href="list_unvalidated.html"
              className="element-menu-top px-2 noDeco mh-100 pointer"
            >
              <span className="mx-2 me-sm-1">
                <Image
                  src={profil}
                  alt=""
                  className="ico  me-sm-2 pro profile__icon"
                />
              </span>
            </NavLink>

            <div
              className="cont-obs projectlist hideContent regular show-block project-menu"
              // style="z-index: 10;"
              style={{ zIndex: "10", top: `${projectListTop}px` }}
              ref={targetElementRef}
            >
              {!isLoading &&
                !projectRefetchLoading &&
                projectList &&
                projectList.data.map((singleProject, index) => (
                  <a
                    href="#"
                    key={index}
                    className={`d-block men-action-item ${
                      singleProject.id === currentProjectId
                        ? "projectSelected"
                        : ""
                    }`}
                    onClick={() => {
                      handleStorageEvent(singleProject);
                      selectProject(singleProject.id, index);
                      dispatch(refetchObs({ refetchObsValue: true }));
                      setCurrentProjectId(singleProject.id);
                      handleSetUserRole(singleProject);
                      handleShowProjectList();
                      setTotalPageNumber(0);
                      setCurrentPage(null);

                      // handleFetchObs(singleProject.id);
                    }}
                  >
                    {singleProject.name}
                  </a>
                ))}
            </div>
          </div>
        </div>
      </div>
      {showCreateModal && (
        <CreateObservation
          handleHideModalCreateModal={handleHideModalCreateModal}
        />
      )}
    </div>
  );
};

export default Header;
